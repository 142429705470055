import React from "react"

export const LineArt2 = () => (
  <svg
    viewBox="0 0 400 400"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>LineArt/2</title>
    <defs>
      <polygon
        id="path-1"
        points="0 0.310994212 100.96733 0.310994212 100.96733 91.5529 0 91.5529"
      ></polygon>
      <polygon
        id="path-3"
        points="0.0039488451 0.372113624 33.2390411 0.372113624 33.2390411 28 0.0039488451 28"
      ></polygon>
    </defs>
    <g
      id="SiteAssets/LineArt/2"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.701497396"
    >
      <g id="2" transform="translate(25.000000, 43.000000)">
        <g
          id="Abstract/LineArt"
          transform="translate(175.000000, 157.500000) rotate(-90.000000) translate(-175.000000, -157.500000) translate(18.000000, -17.000000)"
        >
          <path
            d="M13.5733602,211.815354 L100.638214,277.057776 L80.3826344,224.323276 L13.5733602,211.815354 Z M100.886047,277.5 C100.861164,277.5 100.837276,277.492085 100.816375,277.477246 L13.0458452,211.704551 C13.0030468,211.671903 12.9881171,211.616502 13.010014,211.568025 C13.0309155,211.518559 13.0816764,211.492837 13.1364185,211.501741 L80.4881374,224.111563 C80.5269545,224.118488 80.5597998,224.146189 80.5737341,224.181804 L100.992545,277.345667 C101.010461,277.393154 100.995531,277.446577 100.953728,277.477246 C100.933822,277.492085 100.909934,277.5 100.886047,277.5 L100.886047,277.5 Z"
            id="Fill-1"
            fill="#64C5EC"
          ></path>
          <path
            d="M14.3168621,212.223032 L100.167231,276.484572 L80.1943012,224.543102 L14.3168621,212.223032 Z M100.737603,277.5 C100.68007,277.5 100.624521,277.481287 100.578891,277.446816 L13.1046982,211.967944 C13.007487,211.895062 12.9737606,211.767025 13.0213743,211.654747 C13.0699799,211.545424 13.1850462,211.485345 13.3070561,211.503073 L80.4363372,224.056563 C80.5266048,224.074292 80.6000091,224.13634 80.6327435,224.219071 L100.983607,277.145437 C101.024277,277.259685 100.987575,277.380828 100.892347,277.44977 C100.852669,277.480302 100.79712,277.5 100.737603,277.5 L100.737603,277.5 Z"
            id="Fill-3"
            fill="#66C6EB"
          ></path>
          <path
            d="M92.3940678,279.690924 L95.4305826,294.41754 L100.71096,276.574889 L92.3940678,279.690924 Z M80.4436884,222.864819 L2.49356201,313.119752 L92.1308435,279.544829 L80.4436884,222.864819 Z M2.11412182,313.5 C2.07736976,313.5 2.0426043,313.481988 2.02174502,313.449967 C1.98995946,313.406939 1.99393265,313.348901 2.02770482,313.309876 L80.4218358,222.539606 C80.4516348,222.507586 80.4983198,222.492576 80.5360652,222.503583 C80.5777838,222.51359 80.6105626,222.548612 80.6195023,222.59064 L92.3473827,279.462775 L100.845055,276.279697 C100.88876,276.266688 100.932465,276.274693 100.963258,276.303712 C100.995043,276.333732 101.007956,276.377761 100.995043,276.420789 L95.5199795,294.919868 C95.50508,294.969901 95.4454821,295.009927 95.4057501,295.000921 C95.3531053,294.997919 95.3103934,294.960895 95.2994671,294.909861 L92.1785219,279.771977 L2.15385378,313.492995 C2.14193419,313.496998 2.12802801,313.5 2.11412182,313.5 L2.11412182,313.5 Z"
            id="Fill-5"
            fill="#64C5EC"
          ></path>
          <g id="Group-9" transform="translate(0.500000, 222.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Clip-8"></g>
            <path
              d="M92.239664,57.7109 L95.178764,71.7199 L100.290374,54.7459 L92.239664,57.7109 Z M79.827774,1.1519 L1.156854,90.6769 L91.623564,57.3729 L79.827774,1.1519 Z M0.267044,91.5529 C0.180184,91.5529 0.098374,91.5099 0.047874,91.4379 C-0.021816,91.3399 -0.014746,91.2069 0.064034,91.1139 L79.777274,0.4019 C79.847974,0.3259 79.957054,0.2939 80.049974,0.3199 C80.142894,0.3449 80.220664,0.4239 80.239854,0.5229 L92.130584,57.1869 L100.604484,54.0649 C100.708514,54.0329 100.809514,54.0549 100.882234,54.1209 C100.956974,54.1889 100.985254,54.2919 100.955964,54.3909 L95.388844,72.8769 C95.348444,73.0049 95.199974,73.0879 95.091904,73.0609 C94.998984,73.0609 94.895964,72.9749 94.870714,72.8559 L91.733654,57.8979 L0.359964,91.5349 C0.329664,91.5469 0.298354,91.5529 0.267044,91.5529 L0.267044,91.5529 Z"
              id="Fill-7"
              fill="#66C6EB"
              mask="url(#mask-2)"
            ></path>
          </g>
          <path
            d="M117.34222,117.793402 L145.157857,133.2229 L159.670798,92.8690559 L117.34222,117.793402 Z M145.219717,133.5 C145.20076,133.5 145.180805,133.495104 145.164842,133.484334 L117.058866,117.895234 C117.022948,117.874672 117.000998,117.837464 117,117.797319 C117,117.758153 117.02195,117.720945 117.055873,117.700383 L159.825445,92.5155815 C159.868348,92.4920819 159.921227,92.4950193 159.957145,92.5253731 C159.995059,92.5557268 160.010025,92.6046845 159.993063,92.6497255 L145.329467,133.424605 C145.317494,133.455938 145.292551,133.480417 145.260623,133.492167 C145.247653,133.497063 145.234682,133.5 145.219717,133.5 L145.219717,133.5 Z"
            id="Fill-10"
            fill="#64C5EC"
          ></path>
          <path
            d="M117.783752,117.756046 L145.030774,132.867092 L159.247301,93.3458209 L117.783752,117.756046 Z M145.174445,133.5 C145.126885,133.5 145.080316,133.488333 145.040682,133.464028 L117.133763,117.987432 C117.052514,117.941738 117.002973,117.860073 117,117.767713 C117,117.674381 117.048551,117.589799 117.126827,117.543133 L159.60202,92.536943 C159.703086,92.4795826 159.823968,92.4902769 159.906207,92.560276 C159.989437,92.6263862 160.022135,92.7420791 159.984483,92.8431888 L145.423145,133.328891 C145.395402,133.400835 145.339915,133.457223 145.266593,133.484445 C145.234886,133.495139 145.205161,133.5 145.174445,133.5 L145.174445,133.5 Z"
            id="Fill-12"
            fill="#66C6EB"
          ></path>
          <path
            d="M62.5280477,145.286663 L155.55288,153.240186 L116.385643,117.755469 L62.5280477,145.286663 Z M155.884302,153.5 L155.878258,153.5 L62.1059631,145.483034 C62.0535803,145.477999 62.0112711,145.439732 62.0022048,145.390387 C61.9921312,145.339029 62.0173153,145.28767 62.0626466,145.264508 L116.351393,117.512775 C116.391687,117.490621 116.44407,117.49767 116.481342,117.530902 L155.961869,153.299601 C155.999142,153.332833 156.010223,153.385199 155.990075,153.430515 C155.97295,153.47281 155.930641,153.5 155.884302,153.5 L155.884302,153.5 Z"
            id="Fill-14"
            fill="#64C5EC"
          ></path>
          <path
            d="M63.213988,145.100478 L154.971758,152.904813 L116.337119,118.083398 L63.213988,145.100478 Z M155.733888,153.5 L155.727863,153.5 L62.2440051,145.548865 C62.1265227,145.538879 62.0271146,145.451998 62.0050239,145.336156 C61.981929,145.222312 62.0401682,145.104473 62.144597,145.050546 L116.258797,117.530154 C116.350173,117.479224 116.472676,117.493204 116.557022,117.569101 L155.911617,153.039629 C155.995964,153.115525 156.024079,153.23636 155.97789,153.341217 C155.937725,153.437086 155.841329,153.5 155.733888,153.5 L155.733888,153.5 Z"
            id="Fill-16"
            fill="#66C6EB"
          ></path>
          <path
            d="M22.529977,71.7235429 L78.3880046,104.381309 L35.7938068,71.7235429 L22.529977,71.7235429 Z M79.8854374,105.5 C79.8665204,105.5 79.8446164,105.495119 79.826695,105.483405 L22.0550598,71.7079242 C22.011252,71.6825438 21.9903437,71.6308068 22.0042825,71.5819983 C22.0172258,71.5331898 22.0620293,71.5 22.1138022,71.5 L35.8326365,71.5 C35.8595186,71.5 35.8824182,71.5078094 35.9033265,71.5224519 L79.9561274,105.298909 C80.0039178,105.336003 80.0138741,105.401407 79.9800226,105.451192 C79.9581186,105.482429 79.9222758,105.5 79.8854374,105.5 L79.8854374,105.5 Z"
            id="Fill-18"
            fill="#64C5EC"
          ></path>
          <path
            d="M23.2150938,72.0119841 L76.3006296,102.942015 L35.8215837,72.0119841 L23.2150938,72.0119841 Z M78.2103736,104.314404 L79.2841094,104.939624 L78.2995214,104.187617 L78.2103736,104.314404 Z M79.7377726,105.5 C79.6902271,105.5 79.6426816,105.48645 79.6020699,105.463222 L22.1274907,71.9761742 C22.0254659,71.9161685 21.9769299,71.7961571 22.010608,71.6858241 C22.0393334,71.5783945 22.1443297,71.5 22.2631934,71.5 L35.9107315,71.5 C35.9691729,71.5 36.0256332,71.5183888 36.0711976,71.552263 L79.9002197,105.041247 C80.0081876,105.124481 80.0319604,105.27643 79.954699,105.387731 C79.9051724,105.458383 79.8239488,105.5 79.7377726,105.5 L79.7377726,105.5 Z"
            id="Fill-20"
            fill="#66C6EB"
          ></path>
          <path
            d="M22.2285355,105.733392 L20.2622104,116.23909 L79.5516707,105.733392 L22.2285355,105.733392 Z M20.1164067,116.5 C20.0860309,116.5 20.0556552,116.48777 20.0333796,116.465348 C20.0070539,116.43783 19.9949036,116.399101 20.0019913,116.361392 L22.0179301,105.594784 C22.0290679,105.539748 22.0756441,105.5 22.131333,105.5 L80.8841547,105.5 C80.9428812,105.5 80.99452,105.545863 80.9995826,105.605995 C81.0046452,105.667145 80.9631317,105.721162 80.9033927,105.732373 L20.1366572,116.497962 C20.1305821,116.498981 20.1234944,116.5 20.1164067,116.5 L20.1164067,116.5 Z"
            id="Fill-22"
            fill="#64C5EC"
          ></path>
          <path
            d="M79.4141313,105.797485 L79.4282366,105.953169 L80.3279528,105.797485 L79.4141313,105.797485 Z M22.4932323,106.024565 L20.6001006,115.913955 L77.6771654,106.024565 L22.4932323,106.024565 Z M20.2666112,116.5 C20.1950772,116.5 20.1255583,116.472235 20.0761897,116.421662 C20.014731,116.361174 19.9885354,116.27292 20.0046557,116.188632 L22.0086148,105.714189 C22.0348103,105.590237 22.1446301,105.5 22.2725852,105.5 L80.7339837,105.5 C80.8710065,105.5 80.9878789,105.606103 80.9989617,105.740963 C81.0110519,105.877806 80.9163449,105.997791 80.7783146,106.02159 L20.2666112,116.5 Z"
            id="Fill-24"
            fill="#66C6EB"
          ></path>
          <path
            d="M163.238331,194.642687 L177.743362,210.065485 L173.103026,166.123498 L163.238331,194.642687 Z M177.889127,210.5 C177.859201,210.5 177.831207,210.488013 177.80997,210.46404 L163.030786,194.750567 C163.001826,194.719601 162.992173,194.673653 163.006653,194.632698 L173.055725,165.577107 C173.073101,165.526164 173.118472,165.493201 173.173495,165.501192 C173.224658,165.508184 173.265202,165.550137 173.270994,165.603078 L177.999174,210.372143 C178.004966,210.420089 177.979867,210.468036 177.937393,210.489012 C177.920982,210.496004 177.904572,210.5 177.889127,210.5 L177.889127,210.5 Z"
            id="Fill-26"
            fill="#64C5EC"
          ></path>
          <path
            d="M163.539447,194.562029 L177.419715,209.50673 L172.979204,166.927083 L163.539447,194.562029 Z M177.748326,210.5 C177.680142,210.5 177.615745,210.471224 177.567448,210.419625 L163.070679,194.810098 C163.005336,194.739647 162.982607,194.633473 163.013859,194.540199 L172.872192,165.675821 C172.911019,165.558732 173.017084,165.483319 173.142089,165.503164 C173.255729,165.517056 173.345695,165.613307 173.358953,165.735357 L177.998336,210.209263 C178.010647,210.319405 177.953827,210.427564 177.855338,210.475193 C177.819352,210.492062 177.784312,210.5 177.748326,210.5 L177.748326,210.5 Z"
            id="Fill-28"
            fill="#66C6EB"
          ></path>
          <polygon
            id="Fill-44"
            fill="#66C6EB"
            points="100.694965 201.315734 99.7166019 201.973882 100.773914 201.452765"
          ></polygon>
          <path
            d="M138.39299,71.7502885 L80.7245321,105.165168 L159.639159,91.11979 L138.39299,71.7502885 Z M80.1163249,105.5 C80.0682295,105.5 80.025144,105.471691 80.0071082,105.427762 C79.9880704,105.377 80.0081102,105.319405 80.0572076,105.291096 L138.349905,71.5150274 C138.39299,71.4906228 138.450104,71.4964799 138.487177,71.5286941 L159.962801,91.1080758 C159.995867,91.1363852 160.006889,91.1803136 159.995867,91.2213134 C159.982841,91.2623133 159.948773,91.2925751 159.905688,91.2994084 L80.1363647,105.498048 C80.1293508,105.499024 80.1233389,105.5 80.1163249,105.5 L80.1163249,105.5 Z"
            id="Fill-46"
            fill="#64C5EC"
          ></path>
          <path
            d="M138.303491,72.0743043 L81.6648098,104.735398 L159.170531,91.0064341 L138.303491,72.0743043 Z M80.2652886,105.5 C80.1554831,105.5 80.0576564,105.435154 80.0177271,105.335465 C79.9708102,105.217388 80.0197236,105.08576 80.1325238,105.02285 L138.203668,71.5352114 C138.306485,71.4781082 138.433261,71.4916581 138.518111,71.5671505 L159.915212,90.9803021 C159.988083,91.0451481 160.017031,91.1438689 159.990079,91.2377504 C159.959134,91.3335676 159.880274,91.402285 159.782447,91.4187385 L80.2652886,105.5 Z"
            id="Fill-48"
            fill="#66C6EB"
          ></path>
          <path
            d="M139.110821,60.6363931 L160.235304,91.6002757 L163.253376,78.8508007 L139.110821,60.6363931 Z M160.286869,92 C160.248448,92 160.21306,91.981692 160.191828,91.9501616 L138.520351,60.182762 C138.487996,60.1349578 138.495074,60.071897 138.535517,60.0322297 C138.576971,59.9925624 138.640669,59.9895111 138.686168,60.0230757 L163.45357,78.7094224 C163.49098,78.7368843 163.507157,78.7846885 163.497046,78.8304585 L160.401121,91.9104943 C160.389999,91.9552472 160.3536,91.9898289 160.308102,91.9979658 C160.301024,92 160.293947,92 160.286869,92 L160.286869,92 Z"
            id="Fill-50"
            fill="#64C5EC"
          ></path>
          <path
            d="M139.892697,61.4536925 L160.057555,91.0871774 L162.937535,78.8849764 L139.892697,61.4536925 Z M160.17643,92 C160.088522,92 160.007607,91.9576837 159.95766,91.8851415 L138.546109,60.4179512 C138.473186,60.3101455 138.48817,60.1660686 138.581073,60.0743834 C138.675973,59.9847132 138.820821,59.9746379 138.922714,60.0522177 L163.394057,78.5625667 C163.47697,78.6240261 163.515929,78.7318318 163.493952,78.8356074 L160.433161,91.7954713 C160.409186,91.8982394 160.327272,91.9768268 160.22438,91.9959699 L160.17643,92 Z"
            id="Fill-52"
            fill="#66C6EB"
          ></path>
          <path
            d="M114.913031,45.2865729 L98.8529285,81.6360124 L138.162279,61.3345792 L114.913031,45.2865729 Z M98.6164235,82 C98.5881238,82 98.5588133,81.9891494 98.5375885,81.9694211 C98.5012032,81.9368694 98.4900854,81.8855757 98.5092888,81.8421734 L114.756372,45.0685749 C114.770522,45.037996 114.798822,45.0133356 114.831164,45.0044578 C114.865528,44.9945937 114.901914,45.0014986 114.931224,45.0212269 L138.45033,61.2556659 C138.482673,61.277367 138.501876,61.3158373 138.499855,61.3552939 C138.496823,61.3947506 138.473576,61.4302615 138.437191,61.448017 L98.6710016,81.9871766 C98.6528089,81.9950679 98.6346162,82 98.6164235,82 L98.6164235,82 Z"
            id="Fill-54"
            fill="#64C5EC"
          ></path>
          <path
            d="M115.0052,45.6562536 L99.3098289,81.168201 L137.727225,61.3341976 L115.0052,45.6562536 Z M98.7671564,82 C98.6979431,82 98.6337453,81.9755353 98.5845938,81.9295417 C98.5043465,81.8581049 98.477263,81.7406744 98.520396,81.6398799 L114.647096,45.1571742 C114.682204,45.0828015 114.744396,45.0309364 114.819628,45.0103861 C114.892853,44.9878786 114.977113,45.0025574 115.045323,45.048551 L138.388262,61.1541375 C138.461488,61.2040455 138.504621,61.2911397 138.499606,61.3801912 C138.492584,61.4731569 138.437414,61.5543797 138.356164,61.5954803 L98.8915398,81.9706424 C98.8474037,81.9911927 98.806277,82 98.7671564,82 L98.7671564,82 Z"
            id="Fill-56"
            fill="#66C6EB"
          ></path>
          <path
            d="M212.923748,202.247169 L202.028341,215.563234 L229.875447,203.229893 L212.923748,202.247169 Z M201.616828,216 C201.580068,216 201.544328,215.980918 201.522885,215.950175 C201.49123,215.90459 201.492251,215.840983 201.526969,215.798579 L212.782832,202.043628 C212.807339,202.013945 212.840015,201.994863 212.878818,202.001223 L230.389073,203.01575 C230.443193,203.019991 230.488123,203.060275 230.498334,203.116461 C230.507524,203.171587 230.477911,203.226713 230.428897,203.250035 L201.662779,215.989399 C201.648483,215.99682 201.632145,216 201.616828,216 L201.616828,216 Z"
            id="Fill-58"
            fill="#64C5EC"
          ></path>
          <path
            d="M213.023735,202.556795 L202.707131,215.015264 L229.074593,203.476228 L213.023735,202.556795 Z M201.767421,216 C201.682544,216 201.602719,215.957501 201.552197,215.888051 C201.478435,215.783358 201.483487,215.643422 201.563312,215.547021 L212.702415,202.097597 C212.763042,202.022965 212.854992,201.988758 212.934817,202.00327 L230.247715,202.993189 C230.371999,203.001482 230.474054,203.094772 230.496283,203.21916 C230.516492,203.344584 230.452834,203.467936 230.340676,203.519764 L201.872507,215.978232 C201.844215,215.991707 201.805818,216 201.767421,216 L201.767421,216 Z"
            id="Fill-60"
            fill="#66C6EB"
          ></path>
          <path
            d="M168.798912,19.432295 L148.962627,47.5846599 L170.268266,36.9446523 L168.798912,19.432295 Z M148.612454,48 C148.581066,48 148.548697,47.9861882 148.527118,47.9605378 C148.493768,47.920089 148.490825,47.8628687 148.520252,47.8204468 L168.794008,19.0475381 C168.81951,19.0090624 168.868554,18.9913044 168.914655,19.0041296 C168.960757,19.0169549 168.993126,19.0564171 168.99803,19.1037718 L170.499753,37.0018726 C170.502696,37.0482407 170.479155,37.0926357 170.436977,37.1133534 L148.662479,47.9881613 C148.646785,47.9960538 148.629129,48 148.612454,48 L148.612454,48 Z"
            id="Fill-62"
            fill="#64C5EC"
          ></path>
          <path
            d="M168.564596,19.9863645 L149.553867,47.0527026 L169.972905,36.8228671 L168.564596,19.9863645 Z M148.756309,48 C148.68178,48 148.611122,47.9667958 148.561759,47.9091766 C148.485294,47.8193299 148.479487,47.6884661 148.546272,47.5917832 L168.552981,19.109382 C168.611056,19.0214884 168.720429,18.9794948 168.829803,19.0097693 C168.932402,19.0371139 169.005963,19.1259841 169.017578,19.2334096 L170.499449,36.9547074 C170.506224,37.0640861 170.450085,37.1617456 170.356198,37.2086222 L148.869554,47.9726553 C148.835678,47.990234 148.795025,48 148.756309,48 L148.756309,48 Z"
            id="Fill-64"
            fill="#66C6EB"
          ></path>
          <path
            d="M169.877954,315.636405 L186.257926,320.720957 L186.257926,303.357488 L169.877954,315.636405 Z M186.378963,321 C186.366333,321 186.353703,320.99897 186.342126,320.994852 L169.583255,315.792916 C169.541156,315.78056 169.508528,315.743491 169.502213,315.700245 C169.493793,315.656998 169.509581,315.612722 169.546418,315.58595 L186.305289,303.023872 C186.342126,302.99813 186.389488,302.991952 186.43264,303.011516 C186.473688,303.03211 186.5,303.072267 186.5,303.118603 L186.5,320.882617 C186.5,320.920715 186.481055,320.955724 186.450533,320.977347 C186.429483,320.993822 186.404223,321 186.378963,321 L186.378963,321 Z"
            id="Fill-66"
            fill="#64C5EC"
          ></path>
          <path
            d="M170.356887,315.518135 L185.952391,320.365375 L185.952391,303.812491 L170.356887,315.518135 Z M186.225162,321 L186.139404,320.98583 L169.690457,315.873404 C169.594367,315.843039 169.521008,315.760042 169.504477,315.662874 C169.485878,315.565707 169.526174,315.462466 169.607799,315.403761 L186.059846,303.055395 C186.142504,302.993653 186.254092,302.982519 186.346049,303.027054 C186.443173,303.078674 186.5,303.170781 186.5,303.268961 L186.5,320.730765 C186.5,320.815787 186.457638,320.89676 186.388412,320.947368 C186.34295,320.980769 186.286122,321 186.225162,321 L186.225162,321 Z"
            id="Fill-68"
            fill="#66C6EB"
          ></path>
          <path
            d="M107.555045,8.2720643 L115.754904,8.2720643 L120.524379,0.949256565 L107.555045,8.2720643 Z M115.819999,8.5 L107.119014,8.5 C107.065285,8.5 107.018789,8.46615802 107.004323,8.4163904 C106.989858,8.36662278 107.012589,8.3138691 107.059086,8.28798994 L120.821954,0.516278264 C120.867417,0.488408396 120.928379,0.497366568 120.966609,0.535189959 C121.004839,0.572017999 121.011039,0.630743791 120.981075,0.674539298 L115.920224,8.44625097 C115.898526,8.48009295 115.860295,8.5 115.819999,8.5 L115.819999,8.5 Z"
            id="Fill-70"
            fill="#64C5EC"
          ></path>
          <path
            d="M108.252679,7.99248762 L115.63274,7.99248762 L119.925307,1.49939911 L108.252679,7.99248762 Z M115.780342,8.5 L107.266986,8.5 C107.146681,8.5 107.043562,8.42612769 107.0102,8.31483953 C106.976838,8.20259199 107.027387,8.08554754 107.131516,8.02702532 L120.597601,0.536180899 C120.696675,0.475739916 120.836189,0.492049387 120.924143,0.577434269 C121.011086,0.663778531 121.02524,0.795213685 120.956494,0.893070515 L116.006798,8.38103679 C115.960294,8.45394973 115.87234,8.5 115.780342,8.5 L115.780342,8.5 Z"
            id="Fill-72"
            fill="#66C6EB"
          ></path>
          <path
            d="M246.289093,247.309806 L276.524325,261.766049 L298.897993,253.881889 L246.289093,247.309806 Z M276.516251,262 C276.499095,262 276.481939,261.996101 276.464783,261.988302 L245.564497,247.213302 C245.513028,247.188932 245.488808,247.133368 245.504955,247.080729 C245.521102,247.02809 245.575598,246.989098 245.631103,247.002745 L299.39855,253.718123 C299.452037,253.724947 299.493413,253.764913 299.499468,253.816578 C299.504514,253.868242 299.47323,253.916007 299.42277,253.933553 L276.556619,261.993176 C276.543499,261.997076 276.53038,262 276.516251,262 L276.516251,262 Z"
            id="Fill-74"
            fill="#64C5EC"
          ></path>
          <path
            d="M247.309505,247.700762 L276.510893,261.471284 L298.119399,253.96122 L247.309505,247.700762 Z M276.493833,262 C276.449676,262 276.409534,261.990439 276.372403,261.972273 L245.648624,247.482774 C245.532212,247.43019 245.474006,247.303986 245.511137,247.184475 C245.552283,247.054447 245.679734,246.974136 245.817221,247.007599 L299.267463,253.592171 C299.389897,253.607468 299.484231,253.698296 299.498281,253.814939 C299.51233,253.932538 299.439071,254.041531 299.322659,254.080731 L276.58616,261.983747 C276.559064,261.994263 276.52695,262 276.493833,262 L276.493833,262 Z"
            id="Fill-76"
            fill="#66C6EB"
          ></path>
          <path
            d="M252.537714,320.81271 L285.329483,347.773544 L268.933097,323.783084 L252.537714,320.81271 Z M285.884918,348.5 C285.859854,348.5 285.833786,348.491803 285.812732,348.474384 L252.044439,320.708198 C252.00233,320.674386 251.988294,320.617007 252.010351,320.567826 C252.031405,320.518644 252.085545,320.494053 252.135675,320.501225 L269.01932,323.559717 C269.0504,323.565865 269.076468,323.583283 269.094514,323.608899 L285.979162,348.315568 C286.012248,348.363725 286.00523,348.428277 285.963121,348.469261 C285.941064,348.488729 285.912991,348.5 285.884918,348.5 L285.884918,348.5 Z"
            id="Fill-78"
            fill="#64C5EC"
          ></path>
          <g id="Group-82" transform="translate(252.000000, 320.500000)">
            <mask id="mask-4" fill="white">
              <use xlinkHref="#path-3"></use>
            </mask>
            <g id="Clip-81"></g>
            <path
              d="M1.20505714,1.0771 L31.7399714,26.3651 L16.4720286,3.8631 L1.20505714,1.0771 Z M32.9814571,28.0001 C32.9231714,28.0001 32.8658571,27.9781 32.8202,27.9401 L0.101514286,0.8431 C0.00922857143,0.7671 -0.0218571429,0.6371 0.0267142857,0.5261 C0.0733428571,0.4221 0.179228571,0.3571 0.300657143,0.3751 L16.6663143,3.3611 C16.7323714,3.3721 16.7926,3.4111 16.8324286,3.4711 L33.1932286,27.5831 C33.2660857,27.6931 33.2505429,27.8381 33.1572857,27.9281 C33.1067714,27.9741 33.0446,28.0001 32.9814571,28.0001 L32.9814571,28.0001 Z"
              id="Fill-80"
              fill="#66C6EB"
              mask="url(#mask-4)"
            ></path>
          </g>
          <path
            d="M206.000897,71.3153956 L221.567788,85.7248287 L231.145178,76.5477125 L206.000897,71.3153956 Z M221.56879,86 C221.540736,86 221.512682,85.9908615 221.491641,85.9705536 L205.538008,71.2016717 C205.498933,71.1671483 205.488913,71.109271 205.51296,71.0625629 C205.536004,71.0158549 205.587102,70.9935162 205.6382,71.0016394 L231.407682,76.3649418 C231.450765,76.373065 231.48483,76.4055575 231.495851,76.4471886 C231.506872,76.4888197 231.495851,76.533497 231.46379,76.5639587 L221.64694,85.9685228 C221.625899,85.9908615 221.596843,86 221.56879,86 L221.56879,86 Z"
            id="Fill-83"
            fill="#64C5EC"
          ></path>
          <path
            d="M206.641862,71.712939 L221.530892,85.3770061 L230.690934,76.6749957 L206.641862,71.712939 Z M221.533863,86 C221.467501,86 221.404109,85.9751201 221.355575,85.9303361 L205.586001,71.4571732 C205.498838,71.3785525 205.474076,71.2481816 205.529543,71.1416954 C205.580058,71.0401853 205.690993,70.9854494 205.808861,71.0033629 L231.29117,76.2599982 C231.387247,76.2799022 231.464505,76.3515564 231.491249,76.4461002 C231.516011,76.54562 231.487287,76.646135 231.416962,76.7118181 L221.714132,85.9283458 C221.66857,85.9741249 221.603198,86 221.533863,86 L221.533863,86 Z"
            id="Fill-85"
            fill="#66C6EB"
          ></path>
          <path
            d="M265.555722,113.310255 L241.876177,156.645907 L286.197835,136.836332 L265.555722,113.310255 Z M241.614796,157 C241.583751,157 241.552705,156.987249 241.531675,156.963708 C241.497625,156.928397 241.490615,156.876411 241.512647,156.833253 L265.431541,113.059153 C265.449567,113.026784 265.482615,113.004224 265.520671,113.001282 C265.559728,112.995397 265.59578,113.01011 265.619815,113.038555 L286.472235,136.802983 C286.495269,136.830447 286.505283,136.865758 286.497272,136.901069 C286.48926,136.9354 286.466226,136.964826 286.433178,136.978558 L241.662866,156.98921 C241.646843,156.996077 241.630819,157 241.614796,157 L241.614796,157 Z"
            id="Fill-87"
            fill="#64C5EC"
          ></path>
          <path
            d="M265.074926,113.228463 L241.86394,156.671927 L285.308436,136.813111 L265.074926,113.228463 Z M241.263072,157.5 C241.189456,157.5 241.118824,157.468113 241.070079,157.413306 C240.995468,157.335581 240.977561,157.214011 241.030286,157.113366 L264.791404,112.640541 C264.831197,112.563812 264.907798,112.511995 264.995341,112.50203 C265.077911,112.491069 265.166449,112.524949 265.223153,112.59271 L285.936163,136.735385 C285.989883,136.798164 286.011769,136.883861 285.993863,136.963579 C285.976951,137.04529 285.920246,137.114047 285.844641,137.147927 L241.373497,157.476085 C241.336689,157.492028 241.300875,157.5 241.263072,157.5 L241.263072,157.5 Z"
            id="Fill-89"
            fill="#66C6EB"
          ></path>
          <path
            d="M227.394197,145.925273 L241.671144,157.247557 L285.096612,136.85971 L227.394197,145.925273 Z M241.655314,157.5 C241.631569,157.5 241.606834,157.491857 241.587046,157.47557 L227.043952,145.943595 C227.008333,145.915094 226.992503,145.866234 227.003386,145.821445 C227.015259,145.776657 227.051867,145.742048 227.096389,145.734922 L285.870318,136.501403 C285.926713,136.492242 285.979151,136.528887 285.994981,136.583854 C286.011801,136.63984 285.985087,136.699897 285.933639,136.723309 L241.702805,157.489821 C241.686975,157.496946 241.672134,157.5 241.655314,157.5 L241.655314,157.5 Z"
            id="Fill-91"
            fill="#64C5EC"
          ></path>
          <path
            d="M227.903417,146.035713 L241.76429,156.926083 L283.926741,137.316595 L227.903417,146.035713 Z M241.729835,157.5 C241.673722,157.5 241.617609,157.479933 241.572325,157.444816 L227.102086,146.077854 C227.018408,146.01063 226.981984,145.902268 227.008564,145.799926 C227.035144,145.696581 227.118821,145.620326 227.222187,145.604273 L285.700696,136.502877 C285.824735,136.483814 285.949759,136.561072 285.989136,136.687494 C286.026545,136.815923 285.964525,136.952378 285.846393,137.006559 L241.838123,157.47592 C241.801698,157.491973 241.766259,157.5 241.729835,157.5 L241.729835,157.5 Z"
            id="Fill-93"
            fill="#66C6EB"
          ></path>
          <path
            d="M232.510323,101.656322 L264.875252,111.938445 L241.925739,98.7455097 L232.510323,101.656322 Z M265.88487,112.5 C265.872839,112.5 265.862813,112.497939 265.850782,112.494848 L232.081211,101.768633 C232.032083,101.752147 232,101.706811 232,101.654261 C232,101.602743 232.033086,101.556376 232.081211,101.54195 L241.905687,98.5044017 C241.935765,98.4961587 241.968851,98.4992498 241.994919,98.5157358 L265.941016,112.278469 C265.993151,112.30835 266.013203,112.372233 265.991146,112.428904 C265.972096,112.47321 265.929987,112.5 265.88487,112.5 L265.88487,112.5 Z"
            id="Fill-95"
            fill="#64C5EC"
          ></path>
          <path
            d="M233.165261,101.741557 L263.390571,111.230802 L241.95732,99.0549708 L233.165261,101.741557 Z M265.694293,112.5 L265.618859,112.488911 L232.1866,101.993582 C232.074442,101.957291 232,101.854464 232,101.737525 C232,101.619577 232.074442,101.516751 232.1866,101.482476 L241.912655,98.5116049 C241.985112,98.4894267 242.060546,98.4995077 242.121092,98.5378155 L265.822333,111.999982 C265.937469,112.065509 265.986104,112.206643 265.939454,112.331647 L266,112.366931 L265.933499,112.345761 C265.888834,112.440522 265.796526,112.5 265.694293,112.5 L265.694293,112.5 Z"
            id="Fill-97"
            fill="#66C6EB"
          ></path>
          <path
            d="M299.499989,182.235159 L282.236359,196.518203 L312.95888,184.441491 L299.499989,182.235159 Z M281.613766,197 C281.574236,197 281.536683,196.980132 281.51593,196.94437 C281.487271,196.895693 281.498141,196.83311 281.541624,196.798341 L299.396223,182.026546 C299.419941,182.005684 299.452553,181.99575 299.486153,182.001711 L313.404578,184.283541 C313.455966,184.291488 313.49352,184.332217 313.499449,184.383874 C313.50439,184.434537 313.475731,184.483213 313.428295,184.502088 L281.655272,196.992053 C281.641437,196.99702 281.627601,197 281.613766,197 L281.613766,197 Z"
            id="Fill-99"
            fill="#64C5EC"
          ></path>
          <path
            d="M299.587072,182.030826 L282.73465,195.410964 L312.726489,184.097851 L299.587072,182.030826 Z M281.267422,196.5 C281.172502,196.5 281.085659,196.452269 281.037189,196.373368 C280.969533,196.263295 280.996798,196.121078 281.097777,196.042176 L299.340683,181.558391 C299.399251,181.511635 299.479025,181.492153 299.555769,181.502868 L313.776672,183.739385 C313.895828,183.758867 313.985699,183.850432 313.998827,183.965375 C314.009934,184.08324 313.941269,184.192338 313.831201,184.234224 L281.365372,196.481492 C281.331039,196.494155 281.299735,196.5 281.267422,196.5 L281.267422,196.5 Z"
            id="Fill-101"
            fill="#66C6EB"
          ></path>
          <path
            d="M252.52626,285.915179 L268.981961,290.269543 L311.766584,268.065978 L252.52626,285.915179 Z M268.995994,290.5 C268.98597,290.5 268.973942,290.499028 268.963919,290.49611 L252.085223,286.029921 C252.035105,286.01728 252.001025,285.974494 252.000022,285.92393 C251.99902,285.874338 252.032098,285.830581 252.081213,285.815995 L312.851134,267.504909 C312.905262,267.488378 312.96841,267.514633 312.990462,267.568114 C313.014519,267.621596 312.991464,267.682856 312.939342,267.710083 L269.049119,290.487359 C269.033081,290.49611 269.014036,290.5 268.995994,290.5 L268.995994,290.5 Z"
            id="Fill-103"
            fill="#64C5EC"
          ></path>
          <path
            d="M311.604905,268.12829 L311.665748,268.267514 L312.393865,267.892091 L311.604905,268.12829 Z M253.208913,285.819224 L269.030001,289.973833 L310.168619,268.78888 L253.208913,285.819224 Z M269.062916,290.5 L268.97614,290.484637 L252.195534,286.079427 C252.083823,286.052543 252.003032,285.952686 252.000039,285.839387 C251.998045,285.727049 252.071854,285.628152 252.18556,285.592626 L312.657184,267.510908 C312.794828,267.472502 312.92948,267.538753 312.979351,267.658773 C313.032214,267.773992 312.980348,267.916095 312.860658,267.978506 L269.186596,290.469275 C269.148694,290.489438 269.105805,290.5 269.062916,290.5 L269.062916,290.5 Z"
            id="Fill-105"
            fill="#66C6EB"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default LineArt2
