import React from "react"

export const Placeholder = () => (
  <svg
    viewBox="0 0 80 80"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Assets/Icons/Location"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <circle id="Oval" stroke="#59CFF5" cx="40" cy="40" r="39"></circle>
      <text
        id="Icon"
        fontFamily="Helvetica"
        fontSize="10"
        fontWeight="normal"
        letterSpacing="3"
        fill="#59CFF5"
      >
        <tspan x="24" y="42">
          ICON
        </tspan>
      </text>
    </g>
  </svg>
)

export default Placeholder
