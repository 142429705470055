import React from "react"

export const LineArt1 = () => (
  <svg
    viewBox="0 0 400 400"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>LineArt 1</title>
    <defs>
      <polygon
        id="path-1"
        points="195.872114 186.003886 229.107206 186.003886 229.107206 213.631773 195.872114 213.631773"
      ></polygon>
    </defs>
    <g
      id="SiteAssets/LineArt/1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="1" transform="translate(40.000000, 68.000000)">
        <path
          d="M169.02046,0.315354198 L256.085314,65.5577758 L235.829734,12.8232763 L169.02046,0.315354198 Z M256.333147,66 C256.308264,66 256.284376,65.9920855 256.263475,65.9772457 L168.492945,0.204550808 C168.450147,0.17190338 168.435217,0.116501685 168.457114,0.0680252011 C168.478016,0.0185594016 168.528776,-0.00716281409 168.583519,0.00174102981 L235.935237,12.6115626 C235.974055,12.6184878 236.0069,12.6461887 236.020834,12.6818041 L256.439645,65.8456667 C256.457561,65.8931539 256.442631,65.9465769 256.400828,65.9772457 C256.380922,65.9920855 256.357034,66 256.333147,66 L256.333147,66 Z"
          id="Fill-1"
          fill="#64C5EC"
          transform="translate(212.447100, 33.000000) scale(-1, -1) translate(-212.447100, -33.000000) "
        ></path>
        <path
          d="M169.763962,0.723032418 L255.614331,64.9845717 L235.641401,13.0431022 L169.763962,0.723032418 Z M256.184703,66 C256.12717,66 256.071621,65.981287 256.025991,65.9468156 L168.551798,0.467944235 C168.454587,0.395061897 168.420861,0.267025357 168.468474,0.154747161 C168.51708,0.0454236536 168.632146,-0.0146550305 168.754156,0.00307310582 L235.883437,12.5565634 C235.973705,12.5742915 236.047109,12.63634 236.079843,12.7190713 L256.430707,65.6454373 C256.471377,65.7596853 256.434675,65.8808275 256.339447,65.9497703 C256.299769,65.9803021 256.24422,66 256.184703,66 L256.184703,66 Z"
          id="Fill-3"
          fill="#66C6EB"
          transform="translate(212.447100, 33.000000) scale(-1, -1) translate(-212.447100, -33.000000) "
        ></path>
        <path
          d="M165.738331,151.142625 L180.243362,166.565422 L175.603026,122.623435 L165.738331,151.142625 Z M180.389127,166.999937 C180.359201,166.999937 180.331207,166.987951 180.30997,166.963977 L165.530786,151.250504 C165.501826,151.219539 165.492173,151.17359 165.506653,151.132636 L175.555725,122.077044 C175.573101,122.026101 175.618472,121.993138 175.673495,122.001129 C175.724658,122.008121 175.765202,122.050074 175.770994,122.103015 L180.499174,166.87208 C180.504966,166.920026 180.479867,166.967973 180.437393,166.98895 C180.420982,166.995942 180.404572,166.999937 180.389127,166.999937 L180.389127,166.999937 Z"
          id="Fill-26"
          fill="#64C5EC"
          transform="translate(173.000000, 144.499937) scale(-1, -1) translate(-173.000000, -144.499937) "
        ></path>
        <path
          d="M166.039447,151.061966 L179.919715,166.006667 L175.479204,123.427021 L166.039447,151.061966 Z M180.248326,166.999937 C180.180142,166.999937 180.115745,166.971161 180.067448,166.919563 L165.570679,151.310036 C165.505336,151.239584 165.482607,151.13341 165.513859,151.040136 L175.372192,122.175758 C175.411019,122.058669 175.517084,121.983256 175.642089,122.003102 C175.755729,122.016993 175.845695,122.113244 175.858953,122.235295 L180.498336,166.7092 C180.510647,166.819343 180.453827,166.927501 180.355338,166.97513 C180.319352,166.991999 180.284312,166.999937 180.248326,166.999937 L180.248326,166.999937 Z"
          id="Fill-28"
          fill="#66C6EB"
          transform="translate(173.000000, 144.499937) scale(-1, -1) translate(-173.000000, -144.499937) "
        ></path>
        <polygon
          id="Fill-44"
          fill="#66C6EB"
          transform="translate(201.844390, 59.216539) scale(-1, -1) translate(-201.844390, -59.216539) "
          points="202.294097 58.7165392 201.315734 59.3746871 202.373046 58.85357"
        ></polygon>
        <path
          d="M169.02046,0.315354198 L256.085314,65.5577758 L235.829734,12.8232763 L169.02046,0.315354198 Z M256.333147,66 C256.308264,66 256.284376,65.9920855 256.263475,65.9772457 L168.492945,0.204550808 C168.450147,0.17190338 168.435217,0.116501685 168.457114,0.0680252011 C168.478016,0.0185594016 168.528776,-0.00716281409 168.583519,0.00174102981 L235.935237,12.6115626 C235.974055,12.6184878 236.0069,12.6461887 236.020834,12.6818041 L256.439645,65.8456667 C256.457561,65.8931539 256.442631,65.9465769 256.400828,65.9772457 C256.380922,65.9920855 256.357034,66 256.333147,66 L256.333147,66 Z"
          id="Fill-1"
          fill="#64C5EC"
          transform="translate(212.447100, 33.000000) scale(-1, -1) translate(-212.447100, -33.000000) "
        ></path>
        <path
          d="M10.8168621,192.22297 L96.6672314,256.484509 L76.6943012,204.54304 L10.8168621,192.22297 Z M97.2376031,257.499937 C97.18007,257.499937 97.1245207,257.481224 97.078891,257.446753 L9.60469816,191.967882 C9.50748697,191.894999 9.47376065,191.766963 9.52137429,191.654684 C9.56997988,191.545361 9.68504617,191.485282 9.80705613,191.50301 L76.9363372,204.056501 C77.0266048,204.074229 77.1000091,204.136277 77.1327435,204.219009 L97.4836069,257.145375 C97.5242769,257.259623 97.4875747,257.380765 97.3923474,257.449708 C97.3526694,257.480239 97.2971202,257.499937 97.2376031,257.499937 L97.2376031,257.499937 Z"
          id="Fill-3"
          fill="#66C6EB"
        ></path>
        <polygon
          id="Fill-44"
          fill="#66C6EB"
          transform="translate(201.844390, 59.216539) scale(-1, -1) translate(-201.844390, -59.216539) "
          points="202.294097 58.7165392 201.315734 59.3746871 202.373046 58.85357"
        ></polygon>
        <path
          d="M60.610821,98.1363304 L81.7353041,129.100213 L84.7533763,116.350738 L60.610821,98.1363304 Z M81.7868692,129.499937 C81.7484481,129.499937 81.7130604,129.481629 81.6918277,129.450099 L60.0203506,97.6826993 C59.987996,97.6348951 59.9950736,97.5718343 60.0355168,97.532167 C60.076971,97.4924997 60.140669,97.4894484 60.1861676,97.523013 L84.95357,116.20936 C84.99098,116.236822 85.0071572,116.284626 84.9970464,116.330396 L81.9011211,129.410432 C81.8899993,129.455184 81.8536004,129.489766 81.8081018,129.497903 C81.8010243,129.499937 81.7939467,129.499937 81.7868692,129.499937 L81.7868692,129.499937 Z"
          id="Fill-50"
          fill="#64C5EC"
          transform="translate(72.500000, 113.499937) scale(-1, -1) translate(-72.500000, -113.499937) "
        ></path>
        <path
          d="M61.3926974,98.9536298 L81.5575549,128.587115 L84.4375353,116.384914 L61.3926974,98.9536298 Z M81.6764302,129.499937 C81.5885224,129.499937 81.5076073,129.457621 81.4576596,129.385079 L60.0461093,97.9178886 C59.9731858,97.8100828 59.9881701,97.6660059 60.0810727,97.5743207 C60.1759732,97.4846505 60.3208213,97.4745752 60.4227144,97.552155 L84.8940566,116.062504 C84.9769697,116.123963 85.0159288,116.231769 84.9939519,116.335545 L81.9331611,129.295409 C81.9091862,129.398177 81.8272721,129.476764 81.72438,129.495907 L81.6764302,129.499937 Z"
          id="Fill-52"
          fill="#66C6EB"
          transform="translate(72.500000, 113.499937) scale(-1, -1) translate(-72.500000, -113.499937) "
        ></path>
        <path
          d="M61.4130314,57.7865102 L45.3529285,94.1359497 L84.6622792,73.8345165 L61.4130314,57.7865102 Z M45.1164235,94.4999373 C45.0881238,94.4999373 45.0588133,94.4890867 45.0375885,94.4693584 C45.0012032,94.4368067 44.9900854,94.385513 45.0092888,94.3421107 L61.2563722,57.5685122 C61.270522,57.5379333 61.2988218,57.5132729 61.3311643,57.5043951 C61.3655283,57.494531 61.4019137,57.5014359 61.4312241,57.5211642 L84.9503302,73.7556032 C84.9826727,73.7773043 85.0018761,73.8157746 84.9998547,73.8552312 C84.9968226,73.8946879 84.9735764,73.9301988 84.937191,73.9479543 L45.1710016,94.4871139 C45.1528089,94.4950052 45.1346162,94.4999373 45.1164235,94.4999373 L45.1164235,94.4999373 Z"
          id="Fill-54"
          fill="#64C5EC"
          transform="translate(65.000000, 75.999937) scale(-1, -1) translate(-65.000000, -75.999937) "
        ></path>
        <path
          d="M61.5051995,58.1561909 L45.8098289,93.6681383 L84.2272252,73.8341349 L61.5051995,58.1561909 Z M45.2671564,94.4999373 C45.1979431,94.4999373 45.1337453,94.4754726 45.0845938,94.429479 C45.0043465,94.3580422 44.977263,94.2406117 45.020396,94.1398173 L61.1470959,57.6571115 C61.1822041,57.5827388 61.2443958,57.5308737 61.3196276,57.5103234 C61.3928533,57.4878159 61.477113,57.5024947 61.5453232,57.5484883 L84.8882624,73.6540748 C84.9614881,73.7039828 85.004621,73.791077 84.9996056,73.8801285 C84.9925839,73.9730942 84.9374139,74.054317 84.8561635,74.0954176 L45.3915398,94.4705797 C45.3474037,94.49113 45.306277,94.4999373 45.2671564,94.4999373 L45.2671564,94.4999373 Z"
          id="Fill-56"
          fill="#66C6EB"
          transform="translate(65.000000, 75.999937) scale(-1, -1) translate(-65.000000, -75.999937) "
        ></path>
        <path
          d="M138.423748,205.747107 L127.528341,219.063171 L155.375447,206.72983 L138.423748,205.747107 Z M127.116828,219.499937 C127.080068,219.499937 127.044328,219.480855 127.022885,219.450112 C126.99123,219.404527 126.992251,219.340921 127.026969,219.298516 L138.282832,205.543565 C138.307339,205.513882 138.340015,205.4948 138.378818,205.501161 L155.889073,206.515688 C155.943193,206.519928 155.988123,206.560212 155.998334,206.616398 C156.007524,206.671524 155.977911,206.72665 155.928897,206.749972 L127.162779,219.489336 C127.148483,219.496757 127.132145,219.499937 127.116828,219.499937 L127.116828,219.499937 Z"
          id="Fill-58"
          fill="#64C5EC"
          transform="translate(141.500000, 212.499937) scale(-1, -1) translate(-141.500000, -212.499937) "
        ></path>
        <path
          d="M138.523735,206.056733 L128.207131,218.515201 L154.574593,206.976165 L138.523735,206.056733 Z M127.267421,219.499937 C127.182544,219.499937 127.102719,219.457438 127.052197,219.387988 C126.978435,219.283295 126.983487,219.143359 127.063312,219.046959 L138.202415,205.597535 C138.263042,205.522902 138.354992,205.488695 138.434817,205.503207 L155.747715,206.493126 C155.871999,206.501419 155.974054,206.59471 155.996283,206.719097 C156.016492,206.844522 155.952834,206.967873 155.840676,207.019701 L127.372507,219.478169 C127.344215,219.491645 127.305818,219.499937 127.267421,219.499937 L127.267421,219.499937 Z"
          id="Fill-60"
          fill="#66C6EB"
          transform="translate(141.500000, 212.499937) scale(-1, -1) translate(-141.500000, -212.499937) "
        ></path>
        <path
          d="M39.2989119,107.932232 L19.4626273,136.084597 L40.7682663,125.44459 L39.2989119,107.932232 Z M19.112454,136.499937 C19.081066,136.499937 19.048697,136.486126 19.0271177,136.460475 C18.9937679,136.420026 18.9908252,136.362806 19.0202516,136.320384 L39.2940075,107.547475 C39.3195103,107.509 39.3685542,107.491242 39.4146555,107.504067 C39.4607567,107.516892 39.4931257,107.556354 39.49803,107.603709 L40.9997534,125.50181 C41.0026961,125.548178 40.979155,125.592573 40.9369773,125.613291 L19.1624788,136.488099 C19.1467847,136.495991 19.129129,136.499937 19.112454,136.499937 L19.112454,136.499937 Z"
          id="Fill-62"
          fill="#64C5EC"
          transform="translate(30.000000, 121.999937) scale(-1, -1) translate(-30.000000, -121.999937) "
        ></path>
        <path
          d="M39.0645958,108.486302 L20.0538671,135.55264 L40.4729055,125.322804 L39.0645958,108.486302 Z M19.256309,136.499937 C19.1817799,136.499937 19.1111224,136.466733 19.061759,136.409114 C18.985294,136.319267 18.9794866,136.188403 19.0462724,136.09172 L39.0529809,107.609319 C39.1110555,107.521426 39.2204294,107.479432 39.3298033,107.509707 C39.4324018,107.537051 39.505963,107.625921 39.5175779,107.733347 L40.9994488,125.454645 C41.0062241,125.564023 40.9500853,125.661683 40.856198,125.70856 L19.3695545,136.472593 C19.3356776,136.490171 19.2950254,136.499937 19.256309,136.499937 L19.256309,136.499937 Z"
          id="Fill-64"
          fill="#66C6EB"
          transform="translate(30.000000, 121.999937) scale(-1, -1) translate(-30.000000, -121.999937) "
        ></path>
        <path
          d="M303.377954,141.136342 L319.757926,146.220894 L319.757926,128.857425 L303.377954,141.136342 Z M319.878963,146.499937 C319.866333,146.499937 319.853703,146.498908 319.842126,146.494789 L303.083255,141.292853 C303.041156,141.280497 303.008528,141.243428 303.002213,141.200182 C302.993793,141.156935 303.009581,141.112659 303.046418,141.085888 L319.805289,128.523809 C319.842126,128.498067 319.889488,128.491889 319.93264,128.511453 C319.973688,128.532047 320,128.572204 320,128.61854 L320,146.382554 C320,146.420652 319.981055,146.455661 319.950533,146.477284 C319.929483,146.493759 319.904223,146.499937 319.878963,146.499937 L319.878963,146.499937 Z"
          id="Fill-66"
          fill="#64C5EC"
          transform="translate(311.500000, 137.499937) scale(-1, -1) translate(-311.500000, -137.499937) "
        ></path>
        <path
          d="M303.856887,141.018072 L319.452391,145.865312 L319.452391,129.312428 L303.856887,141.018072 Z M319.725162,146.499937 L319.639404,146.485767 L303.190457,141.373341 C303.094367,141.342976 303.021008,141.259979 303.004477,141.162811 C302.985878,141.065644 303.026174,140.962404 303.107799,140.903698 L319.559846,128.555332 C319.642504,128.49359 319.754092,128.482456 319.846049,128.526991 C319.943173,128.578612 320,128.670718 320,128.768898 L320,146.230702 C320,146.315724 319.957638,146.396697 319.888412,146.447305 C319.84295,146.480706 319.786122,146.499937 319.725162,146.499937 L319.725162,146.499937 Z"
          id="Fill-68"
          fill="#66C6EB"
          transform="translate(311.500000, 137.499937) scale(-1, -1) translate(-311.500000, -137.499937) "
        ></path>
        <path
          d="M1.05504484,73.7720016 L9.25490415,73.7720016 L14.0243788,66.4491939 L1.05504484,73.7720016 Z M9.31999879,73.9999373 L0.619014018,73.9999373 C0.565285101,73.9999373 0.518788923,73.9660953 0.504323445,73.9163277 C0.489857968,73.8665601 0.512589433,73.8138064 0.559085611,73.7879272 L14.3219543,66.0162156 C14.3674172,65.9883457 14.4283789,65.9973039 14.4666091,66.0351273 C14.5048393,66.0719553 14.5110388,66.1306811 14.4810746,66.1744766 L9.42022389,73.9461883 C9.39852567,73.9800303 9.36029548,73.9999373 9.31999879,73.9999373 L9.31999879,73.9999373 Z"
          id="Fill-70"
          fill="#64C5EC"
          transform="translate(7.500000, 69.999937) scale(-1, -1) translate(-7.500000, -69.999937) "
        ></path>
        <path
          d="M1.75267919,73.4924249 L9.13274033,73.4924249 L13.4253074,66.9993364 L1.75267919,73.4924249 Z M9.28034155,73.9999373 L0.766986094,73.9999373 C0.646680988,73.9999373 0.543562326,73.926065 0.510200406,73.8147768 C0.476838485,73.7025293 0.527386849,73.5854848 0.631516479,73.5269626 L14.0976006,66.0361182 C14.1966754,65.9756772 14.3361889,65.9919867 14.4241431,66.0773716 C14.5110863,66.1637158 14.5252398,66.295151 14.456494,66.3930078 L9.50679822,73.8809741 C9.46029373,73.953887 9.37233957,73.9999373 9.28034155,73.9999373 L9.28034155,73.9999373 Z"
          id="Fill-72"
          fill="#66C6EB"
          transform="translate(7.500000, 69.999937) scale(-1, -1) translate(-7.500000, -69.999937) "
        ></path>
        <path
          d="M172.789093,249.809744 L203.024325,264.265986 L225.397993,256.381826 L172.789093,249.809744 Z M203.016251,264.499937 C202.999095,264.499937 202.981939,264.496038 202.964783,264.48824 L172.064497,249.713239 C172.013028,249.688869 171.988808,249.633305 172.004955,249.580666 C172.021102,249.528027 172.075598,249.489035 172.131103,249.502683 L225.89855,256.21806 C225.952037,256.224884 225.993413,256.264851 225.999468,256.316515 C226.004514,256.368179 225.97323,256.415944 225.92277,256.433491 L203.056619,264.493114 C203.043499,264.497013 203.03038,264.499937 203.016251,264.499937 L203.016251,264.499937 Z"
          id="Fill-74"
          fill="#64C5EC"
          transform="translate(199.000000, 256.999937) scale(-1, -1) translate(-199.000000, -256.999937) "
        ></path>
        <path
          d="M173.809505,250.2007 L203.010893,263.971221 L224.619399,256.461157 L173.809505,250.2007 Z M202.993833,264.499937 C202.949676,264.499937 202.909534,264.490376 202.872403,264.472211 L172.148624,249.982712 C172.032212,249.930127 171.974006,249.803923 172.011137,249.684413 C172.052283,249.554385 172.179734,249.474073 172.317221,249.507536 L225.767463,256.092108 C225.889897,256.107405 225.984231,256.198234 225.998281,256.314876 C226.01233,256.432475 225.939071,256.541469 225.822659,256.580668 L203.08616,264.483684 C203.059064,264.494201 203.02695,264.499937 202.993833,264.499937 L202.993833,264.499937 Z"
          id="Fill-76"
          fill="#66C6EB"
          transform="translate(199.000000, 256.999937) scale(-1, -1) translate(-199.000000, -256.999937) "
        ></path>
        <path
          d="M196.037714,186.312647 L228.829483,213.273482 L212.433097,189.283021 L196.037714,186.312647 Z M229.384918,213.999937 C229.359854,213.999937 229.333786,213.99174 229.312732,213.974322 L195.544439,186.208136 C195.50233,186.174323 195.488294,186.116945 195.510351,186.067763 C195.531405,186.018581 195.585545,185.99399 195.635675,186.001163 L212.51932,189.059654 C212.5504,189.065802 212.576468,189.08322 212.594514,189.108836 L229.479162,213.815506 C229.512248,213.863663 229.50523,213.928214 229.463121,213.969199 C229.441064,213.988666 229.412991,213.999937 229.384918,213.999937 L229.384918,213.999937 Z"
          id="Fill-78"
          fill="#64C5EC"
          transform="translate(212.500000, 199.999937) scale(-1, -1) translate(-212.500000, -199.999937) "
        ></path>
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1"></use>
        </mask>
        <g
          id="Clip-81"
          transform="translate(212.489660, 199.817829) scale(-1, -1) translate(-212.489660, -199.817829) "
        ></g>
        <path
          d="M197.073222,186.708873 L227.608136,211.996873 L212.340193,189.494873 L197.073222,186.708873 Z M228.849622,213.631873 C228.791336,213.631873 228.734022,213.609873 228.688365,213.571873 L195.969679,186.474873 C195.877393,186.398873 195.846308,186.268873 195.894879,186.157873 C195.941508,186.053873 196.047393,185.988873 196.168822,186.006873 L212.534479,188.992873 C212.600536,189.003873 212.660765,189.042873 212.700593,189.102873 L229.061393,213.214873 C229.13425,213.324873 229.118708,213.469873 229.02545,213.559873 C228.974936,213.605873 228.912765,213.631873 228.849622,213.631873 L228.849622,213.631873 Z"
          id="Fill-80"
          fill="#66C6EB"
          mask="url(#mask-2)"
          transform="translate(212.489660, 199.817879) scale(-1, -1) translate(-212.489660, -199.817879) "
        ></path>
        <path
          d="M-3.49910345,189.815333 L12.0677877,204.224766 L21.6451779,195.04765 L-3.49910345,189.815333 Z M12.0687896,204.499937 C12.0407357,204.499937 12.0126819,204.490799 11.9916415,204.470491 L-3.96199228,189.701609 C-4.00106731,189.667086 -4.01108655,189.609208 -3.98704038,189.5625 C-3.96399613,189.515792 -3.91289801,189.493454 -3.86179989,189.501577 L21.907682,194.864879 C21.9507647,194.873002 21.9848301,194.905495 21.9958513,194.947126 C22.0068724,194.988757 21.9958513,195.033434 21.9637897,195.063896 L12.1469397,204.46846 C12.1258993,204.490799 12.0968435,204.499937 12.0687896,204.499937 L12.0687896,204.499937 Z"
          id="Fill-83"
          fill="#64C5EC"
          mask="url(#mask-2)"
          transform="translate(9.000000, 196.999937) scale(-1, -1) translate(-9.000000, -196.999937) "
        ></path>
        <path
          d="M-2.85813761,190.212876 L12.0308919,203.876943 L21.1909335,195.174933 L-2.85813761,190.212876 Z M12.0338634,204.499937 C11.9675006,204.499937 11.9041093,204.475057 11.8555754,204.430273 L-3.91399881,189.95711 C-4.00116183,189.87849 -4.02592406,189.748119 -3.97045668,189.641633 C-3.91994174,189.540123 -3.80900698,189.485387 -3.6911388,189.5033 L21.7911698,194.759936 C21.8872472,194.779839 21.9645054,194.851494 21.9912486,194.946038 C22.0160108,195.045557 21.9872866,195.146072 21.9169619,195.211755 L12.2141324,204.428283 C12.1685699,204.474062 12.1031976,204.499937 12.0338634,204.499937 L12.0338634,204.499937 Z"
          id="Fill-85"
          fill="#66C6EB"
          mask="url(#mask-2)"
          transform="translate(9.000000, 196.999937) scale(-1, -1) translate(-9.000000, -196.999937) "
        ></path>
        <path
          d="M62.0557217,225.810192 L38.3761774,269.145845 L82.6978349,249.336269 L62.0557217,225.810192 Z M38.1147961,269.499937 C38.0837508,269.499937 38.0527055,269.487186 38.0316748,269.463645 C37.9976251,269.428334 37.9906149,269.376348 38.012647,269.33319 L61.9315405,225.55909 C61.9495668,225.526722 61.982615,225.504162 62.0206706,225.501219 C62.0597275,225.495334 62.0957801,225.510047 62.1198152,225.538492 L82.9722353,249.30292 C82.9952689,249.330384 83.0052835,249.365695 82.9972718,249.401007 C82.9892601,249.435337 82.9662265,249.464763 82.9331783,249.478495 L38.1628662,269.489148 C38.1468428,269.496014 38.1308194,269.499937 38.1147961,269.499937 L38.1147961,269.499937 Z"
          id="Fill-87"
          fill="#64C5EC"
          mask="url(#mask-2)"
          transform="translate(60.500000, 247.499937) scale(-1, -1) translate(-60.500000, -247.499937) "
        ></path>
        <path
          d="M61.5749262,225.7284 L38.3639396,269.171864 L81.8084362,249.313048 L61.5749262,225.7284 Z M37.7630723,269.999937 C37.6894561,269.999937 37.6188244,269.96805 37.5700785,269.913244 C37.4954675,269.835518 37.4775609,269.713948 37.530286,269.613304 L61.2914044,225.140478 C61.3311969,225.063749 61.4077975,225.011932 61.4953411,225.001967 C61.5779106,224.991006 61.666449,225.024886 61.7231534,225.092647 L82.4361635,249.235323 C82.4898834,249.298101 82.5117693,249.383798 82.4938627,249.463516 C82.4769508,249.545228 82.4202465,249.613985 82.3446406,249.647865 L37.8734966,269.976022 C37.8366885,269.991965 37.8008752,269.999937 37.7630723,269.999937 L37.7630723,269.999937 Z"
          id="Fill-89"
          fill="#66C6EB"
          mask="url(#mask-2)"
          transform="translate(60.000000, 247.499937) scale(-1, -1) translate(-60.000000, -247.499937) "
        ></path>
        <path
          d="M61.8941969,220.42521 L76.1711443,231.747494 L119.596612,211.359647 L61.8941969,220.42521 Z M76.155314,231.999937 C76.1315686,231.999937 76.1068337,231.991794 76.0870459,231.975507 L61.5439516,220.443533 C61.5083334,220.415031 61.4925031,220.366171 61.5033865,220.321383 C61.5152592,220.276594 61.5518667,220.241985 61.5963894,220.23486 L120.370318,211.00134 C120.426713,210.992179 120.479151,211.028824 120.494981,211.083792 C120.511801,211.139777 120.485087,211.199834 120.433639,211.223246 L76.2028049,231.989758 C76.1869746,231.996884 76.1721337,231.999937 76.155314,231.999937 L76.155314,231.999937 Z"
          id="Fill-91"
          fill="#64C5EC"
          mask="url(#mask-2)"
          transform="translate(91.000000, 221.499937) scale(-1, -1) translate(-91.000000, -221.499937) "
        ></path>
        <path
          d="M62.4034172,220.535651 L76.2642898,231.426021 L118.426741,211.816532 L62.4034172,220.535651 Z M76.2298345,231.999937 C76.1737216,231.999937 76.1176087,231.97987 76.0723246,231.944753 L61.6020855,220.577791 C61.5184084,220.510567 61.4819842,220.402205 61.508564,220.299863 C61.5351438,220.196518 61.618821,220.120263 61.7221868,220.10421 L120.200696,211.002815 C120.324735,210.983751 120.449759,211.061009 120.489136,211.187431 C120.526545,211.31586 120.464525,211.452316 120.346393,211.506497 L76.3381226,231.975857 C76.3016984,231.99191 76.2662587,231.999937 76.2298345,231.999937 L76.2298345,231.999937 Z"
          id="Fill-93"
          fill="#66C6EB"
          mask="url(#mask-2)"
          transform="translate(91.000000, 221.499937) scale(-1, -1) translate(-91.000000, -221.499937) "
        ></path>
        <path
          d="M24.0103234,219.156259 L56.3752518,229.438382 L33.4257393,216.245447 L24.0103234,219.156259 Z M57.38487,229.999937 C57.3728388,229.999937 57.3628128,229.997877 57.3507816,229.994785 L23.5812106,219.26857 C23.5320832,219.252084 23.5,219.206748 23.5,219.154199 C23.5,219.10268 23.5330858,219.056313 23.5812106,219.041888 L33.4056873,216.004339 C33.4357653,215.996096 33.4688511,215.999187 33.4949187,216.015673 L57.4410156,229.778406 C57.4931508,229.808287 57.5132028,229.872171 57.4911456,229.928841 C57.4720962,229.973148 57.429987,229.999937 57.38487,229.999937 L57.38487,229.999937 Z"
          id="Fill-95"
          fill="#64C5EC"
          mask="url(#mask-2)"
          transform="translate(40.500000, 222.999937) scale(-1, -1) translate(-40.500000, -222.999937) "
        ></path>
        <path
          d="M24.6652605,219.241495 L54.8905707,228.730739 L33.4573201,216.554908 L24.6652605,219.241495 Z M57.1942928,229.999937 L57.1188586,229.988848 L23.6866005,219.49352 C23.5744417,219.457228 23.5,219.354402 23.5,219.237462 C23.5,219.119514 23.5744417,219.016688 23.6866005,218.982413 L33.4126551,216.011542 C33.4851117,215.989364 33.5605459,215.999445 33.6210918,216.037753 L57.3223325,229.49992 C57.437469,229.565446 57.4861042,229.70658 57.4394541,229.831585 L57.5,229.866868 L57.4334988,229.845698 C57.3888337,229.940459 57.2965261,229.999937 57.1942928,229.999937 L57.1942928,229.999937 Z"
          id="Fill-97"
          fill="#66C6EB"
          mask="url(#mask-2)"
          transform="translate(40.500000, 222.999937) scale(-1, -1) translate(-40.500000, -222.999937) "
        ></path>
        <path
          d="M124.999989,265.735096 L107.736359,280.01814 L138.45888,267.941428 L124.999989,265.735096 Z M107.113766,280.499937 C107.074236,280.499937 107.036683,280.480069 107.01593,280.444307 C106.987271,280.395631 106.998141,280.333047 107.041624,280.298278 L124.896223,265.526483 C124.919941,265.505622 124.952553,265.495688 124.986153,265.501648 L138.904578,267.783478 C138.955966,267.791425 138.99352,267.832154 138.999449,267.883811 C139.00439,267.934474 138.975731,267.983151 138.928295,268.002025 L107.155272,280.49199 C107.141437,280.496957 107.127601,280.499937 107.113766,280.499937 L107.113766,280.499937 Z"
          id="Fill-99"
          fill="#64C5EC"
          mask="url(#mask-2)"
          transform="translate(123.000000, 272.999937) scale(-1, -1) translate(-123.000000, -272.999937) "
        ></path>
        <path
          d="M125.087072,265.530764 L108.23465,278.910902 L138.226489,267.597788 L125.087072,265.530764 Z M106.767422,279.999937 C106.672502,279.999937 106.585659,279.952207 106.537189,279.873305 C106.469533,279.763233 106.496798,279.621015 106.597777,279.542114 L124.840683,265.058329 C124.899251,265.011572 124.979025,264.99209 125.055769,265.002805 L139.276672,267.239322 C139.395828,267.258804 139.485699,267.350369 139.498827,267.465312 C139.509934,267.583177 139.441269,267.692275 139.331201,267.734161 L106.865372,279.98143 C106.831039,279.994093 106.799735,279.999937 106.767422,279.999937 L106.767422,279.999937 Z"
          id="Fill-101"
          fill="#66C6EB"
          mask="url(#mask-2)"
          transform="translate(123.000000, 272.499937) scale(-1, -1) translate(-123.000000, -272.499937) "
        ></path>
        <path
          d="M193.02626,274.415116 L209.481961,278.769481 L252.266584,256.565915 L193.02626,274.415116 Z M209.495994,278.999937 C209.48597,278.999937 209.473942,278.998965 209.463919,278.996048 L192.585223,274.529858 C192.535105,274.517217 192.501025,274.474432 192.500022,274.423867 C192.49902,274.374276 192.532098,274.330518 192.581213,274.315932 L253.351134,256.004846 C253.405262,255.988315 253.46841,256.01457 253.490462,256.068051 C253.514519,256.121533 253.491464,256.182793 253.439342,256.21002 L209.549119,278.987296 C209.533081,278.996048 209.514036,278.999937 209.495994,278.999937 L209.495994,278.999937 Z"
          id="Fill-103"
          fill="#64C5EC"
          mask="url(#mask-2)"
          transform="translate(223.000000, 267.499937) scale(-1, -1) translate(-223.000000, -267.499937) "
        ></path>
        <path
          d="M252.104905,256.628228 L252.165748,256.767451 L252.893865,256.392029 L252.104905,256.628228 Z M193.708913,274.319161 L209.530001,278.47377 L250.668619,257.288817 L193.708913,274.319161 Z M209.562916,278.999937 L209.47614,278.984575 L192.695534,274.579364 C192.583823,274.55248 192.503032,274.452623 192.500039,274.339325 C192.498045,274.226986 192.571854,274.128089 192.68556,274.092564 L253.157184,256.010845 C253.294828,255.972439 253.42948,256.03869 253.479351,256.15871 C253.532214,256.273929 253.480348,256.416033 253.360658,256.478443 L209.686596,278.969212 C209.648694,278.989376 209.605805,278.999937 209.562916,278.999937 L209.562916,278.999937 Z"
          id="Fill-105"
          fill="#66C6EB"
          mask="url(#mask-2)"
          transform="translate(223.000000, 267.499937) scale(-1, -1) translate(-223.000000, -267.499937) "
        ></path>
      </g>
    </g>
  </svg>
)

export default LineArt1
