import React from "react"

export const LineArt3 = () => (
  <svg
    viewBox="0 0 400 400"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>LineArt/3</title>
    <defs>
      <polygon
        id="path-1"
        points="0.0039488451 0.372113624 33.2390411 0.372113624 33.2390411 28 0.0039488451 28"
      ></polygon>
    </defs>
    <g
      id="SiteAssets/LineArt/3"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.701497396"
    >
      <g
        id="3"
        transform="translate(200.500000, 200.000000) rotate(90.000000) translate(-200.500000, -200.000000) translate(92.500000, 25.500000)"
      >
        <path
          d="M65.2383314,194.642687 L79.7433619,210.065485 L75.1030264,166.123498 L65.2383314,194.642687 Z M79.8891265,210.5 C79.8592013,210.5 79.8312068,210.488013 79.8099696,210.46404 L65.0307858,194.750567 C65.0018259,194.719601 64.9921726,194.673653 65.0066525,194.632698 L75.0557253,165.577107 C75.0731012,165.526164 75.1184716,165.493201 75.1734954,165.501192 C75.2246578,165.508184 75.2652016,165.550137 75.2709936,165.603078 L79.999174,210.372143 C80.004966,210.420089 79.9798674,210.468036 79.937393,210.489012 C79.9209824,210.496004 79.9045718,210.5 79.8891265,210.5 L79.8891265,210.5 Z"
          id="Fill-26"
          fill="#64C5EC"
        ></path>
        <path
          d="M65.539447,194.562029 L79.4197151,209.50673 L74.9792036,166.927083 L65.539447,194.562029 Z M79.7483262,210.5 C79.6801418,210.5 79.6157453,210.471224 79.567448,210.419625 L65.070679,194.810098 C65.0053356,194.739647 64.9826075,194.633473 65.0138587,194.540199 L74.8721919,165.675821 C74.9110192,165.558732 75.0170838,165.483319 75.1420886,165.503164 C75.2557294,165.517056 75.3456949,165.613307 75.358953,165.735357 L79.9983358,210.209263 C80.0106469,210.319405 79.9538265,210.427564 79.8553379,210.475193 C79.8193516,210.492062 79.7843124,210.5 79.7483262,210.5 L79.7483262,210.5 Z"
          id="Fill-28"
          fill="#66C6EB"
        ></path>
        <path
          d="M41.110821,60.6363931 L62.2353041,91.6002757 L65.2533763,78.8508007 L41.110821,60.6363931 Z M62.2868692,92 C62.2484481,92 62.2130604,91.981692 62.1918277,91.9501616 L40.5203506,60.182762 C40.487996,60.1349578 40.4950736,60.071897 40.5355168,60.0322297 C40.576971,59.9925624 40.640669,59.9895111 40.6861676,60.0230757 L65.45357,78.7094224 C65.49098,78.7368843 65.5071572,78.7846885 65.4970464,78.8304585 L62.4011211,91.9104943 C62.3899993,91.9552472 62.3536004,91.9898289 62.3081018,91.9979658 C62.3010243,92 62.2939467,92 62.2868692,92 L62.2868692,92 Z"
          id="Fill-50"
          fill="#64C5EC"
        ></path>
        <path
          d="M41.8926974,61.4536925 L62.0575549,91.0871774 L64.9375353,78.8849764 L41.8926974,61.4536925 Z M62.1764302,92 C62.0885224,92 62.0076073,91.9576837 61.9576596,91.8851415 L40.5461093,60.4179512 C40.4731858,60.3101455 40.4881701,60.1660686 40.5810727,60.0743834 C40.6759732,59.9847132 40.8208213,59.9746379 40.9227144,60.0522177 L65.3940566,78.5625667 C65.4769697,78.6240261 65.5159288,78.7318318 65.4939519,78.8356074 L62.4331611,91.7954713 C62.4091862,91.8982394 62.3272721,91.9768268 62.22438,91.9959699 L62.1764302,92 Z"
          id="Fill-52"
          fill="#66C6EB"
        ></path>
        <path
          d="M16.9130314,45.2865729 L0.852928505,81.6360124 L40.1622792,61.3345792 L16.9130314,45.2865729 Z M0.616423528,82 C0.588123788,82 0.558813342,81.9891494 0.537588536,81.9694211 C0.501203155,81.9368694 0.4900854,81.8855757 0.509288795,81.8421734 L16.7563722,45.0685749 C16.770522,45.037996 16.7988218,45.0133356 16.8311643,45.0044578 C16.8655283,44.9945937 16.9019137,45.0014986 16.9312241,45.0212269 L40.4503302,61.2556659 C40.4826727,61.277367 40.5018761,61.3158373 40.4998547,61.3552939 C40.4968226,61.3947506 40.4735764,61.4302615 40.437191,61.448017 L0.6710016,81.9871766 C0.652808909,81.9950679 0.634616219,82 0.616423528,82 L0.616423528,82 Z"
          id="Fill-54"
          fill="#64C5EC"
        ></path>
        <path
          d="M17.0051995,45.6562536 L1.30982886,81.168201 L39.7272252,61.3341976 L17.0051995,45.6562536 Z M0.767156431,82 C0.697943126,82 0.633745279,81.9755353 0.584593801,81.9295417 C0.504346492,81.8581049 0.477263025,81.7406744 0.520395954,81.6398799 L16.6470959,45.1571742 C16.6822041,45.0828015 16.7443958,45.0309364 16.8196276,45.0103861 C16.8928533,44.9878786 16.977113,45.0025574 17.0453232,45.048551 L40.3882624,61.1541375 C40.4614881,61.2040455 40.504621,61.2911397 40.4996056,61.3801912 C40.4925839,61.4731569 40.4374139,61.5543797 40.3561635,61.5954803 L0.891539761,81.9706424 C0.84740374,81.9911927 0.806276994,82 0.767156431,82 L0.767156431,82 Z"
          id="Fill-56"
          fill="#66C6EB"
        ></path>
        <path
          d="M114.923748,202.247169 L104.028341,215.563234 L131.875447,203.229893 L114.923748,202.247169 Z M103.616828,216 C103.580068,216 103.544328,215.980918 103.522885,215.950175 C103.49123,215.90459 103.492251,215.840983 103.526969,215.798579 L114.782832,202.043628 C114.807339,202.013945 114.840015,201.994863 114.878818,202.001223 L132.389073,203.01575 C132.443193,203.019991 132.488123,203.060275 132.498334,203.116461 C132.507524,203.171587 132.477911,203.226713 132.428897,203.250035 L103.662779,215.989399 C103.648483,215.99682 103.632145,216 103.616828,216 L103.616828,216 Z"
          id="Fill-58"
          fill="#64C5EC"
        ></path>
        <path
          d="M115.023735,202.556795 L104.707131,215.015264 L131.074593,203.476228 L115.023735,202.556795 Z M103.767421,216 C103.682544,216 103.602719,215.957501 103.552197,215.888051 C103.478435,215.783358 103.483487,215.643422 103.563312,215.547021 L114.702415,202.097597 C114.763042,202.022965 114.854992,201.988758 114.934817,202.00327 L132.247715,202.993189 C132.371999,203.001482 132.474054,203.094772 132.496283,203.21916 C132.516492,203.344584 132.452834,203.467936 132.340676,203.519764 L103.872507,215.978232 C103.844215,215.991707 103.805818,216 103.767421,216 L103.767421,216 Z"
          id="Fill-60"
          fill="#66C6EB"
        ></path>
        <path
          d="M70.7989119,19.432295 L50.9626273,47.5846599 L72.2682663,36.9446523 L70.7989119,19.432295 Z M50.612454,48 C50.581066,48 50.548697,47.9861882 50.5271177,47.9605378 C50.4937679,47.920089 50.4908252,47.8628687 50.5202516,47.8204468 L70.7940075,19.0475381 C70.8195103,19.0090624 70.8685542,18.9913044 70.9146555,19.0041296 C70.9607567,19.0169549 70.9931257,19.0564171 70.99803,19.1037718 L72.4997534,37.0018726 C72.5026961,37.0482407 72.479155,37.0926357 72.4369773,37.1133534 L50.6624788,47.9881613 C50.6467847,47.9960538 50.629129,48 50.612454,48 L50.612454,48 Z"
          id="Fill-62"
          fill="#64C5EC"
        ></path>
        <path
          d="M70.5645958,19.9863645 L51.5538671,47.0527026 L71.9729055,36.8228671 L70.5645958,19.9863645 Z M50.756309,48 C50.6817799,48 50.6111224,47.9667958 50.561759,47.9091766 C50.485294,47.8193299 50.4794866,47.6884661 50.5462724,47.5917832 L70.5529809,19.109382 C70.6110555,19.0214884 70.7204294,18.9794948 70.8298033,19.0097693 C70.9324018,19.0371139 71.005963,19.1259841 71.0175779,19.2334096 L72.4994488,36.9547074 C72.5062241,37.0640861 72.4500853,37.1617456 72.356198,37.2086222 L50.8695545,47.9726553 C50.8356776,47.990234 50.7950254,48 50.756309,48 L50.756309,48 Z"
          id="Fill-64"
          fill="#66C6EB"
        ></path>
        <path
          d="M71.877954,315.636405 L88.2579263,320.720957 L88.2579263,303.357488 L71.877954,315.636405 Z M88.3789631,321 C88.3663332,321 88.3537033,320.99897 88.3421258,320.994852 L71.5832555,315.792916 C71.5411557,315.78056 71.5085284,315.743491 71.5022134,315.700245 C71.4937935,315.656998 71.5095809,315.612722 71.5464182,315.58595 L88.3052885,303.023872 C88.3421258,302.99813 88.3894881,302.991952 88.4326404,303.011516 C88.4736876,303.03211 88.5,303.072267 88.5,303.118603 L88.5,320.882617 C88.5,320.920715 88.4810551,320.955724 88.4505328,320.977347 C88.4294829,320.993822 88.404223,321 88.3789631,321 L88.3789631,321 Z"
          id="Fill-66"
          fill="#64C5EC"
        ></path>
        <path
          d="M72.3568874,315.518135 L87.9523906,320.365375 L87.9523906,303.812491 L72.3568874,315.518135 Z M88.2251621,321 L88.1394044,320.98583 L71.6904571,315.873404 C71.5943671,315.843039 71.5210081,315.760042 71.5044765,315.662874 C71.4858785,315.565707 71.5261742,315.462466 71.607799,315.403761 L88.059846,303.055395 C88.142504,302.993653 88.2540924,302.982519 88.3460494,303.027054 C88.4431726,303.078674 88.5,303.170781 88.5,303.268961 L88.5,320.730765 C88.5,320.815787 88.4576378,320.89676 88.3884117,320.947368 C88.3429497,320.980769 88.2861224,321 88.2251621,321 L88.2251621,321 Z"
          id="Fill-68"
          fill="#66C6EB"
        ></path>
        <path
          d="M9.55504484,8.2720643 L17.7549041,8.2720643 L22.5243788,0.949256565 L9.55504484,8.2720643 Z M17.8199988,8.5 L9.11901402,8.5 C9.0652851,8.5 9.01878892,8.46615802 9.00432345,8.4163904 C8.98985797,8.36662278 9.01258943,8.3138691 9.05908561,8.28798994 L22.8219543,0.516278264 C22.8674172,0.488408396 22.9283789,0.497366568 22.9666091,0.535189959 C23.0048393,0.572017999 23.0110388,0.630743791 22.9810746,0.674539298 L17.9202239,8.44625097 C17.8985257,8.48009295 17.8602955,8.5 17.8199988,8.5 L17.8199988,8.5 Z"
          id="Fill-70"
          fill="#64C5EC"
        ></path>
        <path
          d="M10.2526792,7.99248762 L17.6327403,7.99248762 L21.9253074,1.49939911 L10.2526792,7.99248762 Z M17.7803416,8.5 L9.26698609,8.5 C9.14668099,8.5 9.04356233,8.42612769 9.01020041,8.31483953 C8.97683849,8.20259199 9.02738685,8.08554754 9.13151648,8.02702532 L22.5976006,0.536180899 C22.6966754,0.475739916 22.8361889,0.492049387 22.9241431,0.577434269 C23.0110863,0.663778531 23.0252398,0.795213685 22.956494,0.893070515 L18.0067982,8.38103679 C17.9602937,8.45394973 17.8723396,8.5 17.7803416,8.5 L17.7803416,8.5 Z"
          id="Fill-72"
          fill="#66C6EB"
        ></path>
        <path
          d="M148.289093,247.309806 L178.524325,261.766049 L200.897993,253.881889 L148.289093,247.309806 Z M178.516251,262 C178.499095,262 178.481939,261.996101 178.464783,261.988302 L147.564497,247.213302 C147.513028,247.188932 147.488808,247.133368 147.504955,247.080729 C147.521102,247.02809 147.575598,246.989098 147.631103,247.002745 L201.39855,253.718123 C201.452037,253.724947 201.493413,253.764913 201.499468,253.816578 C201.504514,253.868242 201.47323,253.916007 201.42277,253.933553 L178.556619,261.993176 C178.543499,261.997076 178.53038,262 178.516251,262 L178.516251,262 Z"
          id="Fill-74"
          fill="#64C5EC"
        ></path>
        <path
          d="M149.309505,247.700762 L178.510893,261.471284 L200.119399,253.96122 L149.309505,247.700762 Z M178.493833,262 C178.449676,262 178.409534,261.990439 178.372403,261.972273 L147.648624,247.482774 C147.532212,247.43019 147.474006,247.303986 147.511137,247.184475 C147.552283,247.054447 147.679734,246.974136 147.817221,247.007599 L201.267463,253.592171 C201.389897,253.607468 201.484231,253.698296 201.498281,253.814939 C201.51233,253.932538 201.439071,254.041531 201.322659,254.080731 L178.58616,261.983747 C178.559064,261.994263 178.52695,262 178.493833,262 L178.493833,262 Z"
          id="Fill-76"
          fill="#66C6EB"
        ></path>
        <path
          d="M154.537714,320.81271 L187.329483,347.773544 L170.933097,323.783084 L154.537714,320.81271 Z M187.884918,348.5 C187.859854,348.5 187.833786,348.491803 187.812732,348.474384 L154.044439,320.708198 C154.00233,320.674386 153.988294,320.617007 154.010351,320.567826 C154.031405,320.518644 154.085545,320.494053 154.135675,320.501225 L171.01932,323.559717 C171.0504,323.565865 171.076468,323.583283 171.094514,323.608899 L187.979162,348.315568 C188.012248,348.363725 188.00523,348.428277 187.963121,348.469261 C187.941064,348.488729 187.912991,348.5 187.884918,348.5 L187.884918,348.5 Z"
          id="Fill-78"
          fill="#64C5EC"
        ></path>
        <g id="Group-82" transform="translate(154.000000, 320.500000)">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"></use>
          </mask>
          <g id="Clip-81"></g>
          <path
            d="M1.20505714,1.0771 L31.7399714,26.3651 L16.4720286,3.8631 L1.20505714,1.0771 Z M32.9814571,28.0001 C32.9231714,28.0001 32.8658571,27.9781 32.8202,27.9401 L0.101514286,0.8431 C0.00922857143,0.7671 -0.0218571429,0.6371 0.0267142857,0.5261 C0.0733428571,0.4221 0.179228571,0.3571 0.300657143,0.3751 L16.6663143,3.3611 C16.7323714,3.3721 16.7926,3.4111 16.8324286,3.4711 L33.1932286,27.5831 C33.2660857,27.6931 33.2505429,27.8381 33.1572857,27.9281 C33.1067714,27.9741 33.0446,28.0001 32.9814571,28.0001 L32.9814571,28.0001 Z"
            id="Fill-80"
            fill="#66C6EB"
            mask="url(#mask-2)"
          ></path>
        </g>
        <path
          d="M108.000897,71.3153956 L123.567788,85.7248287 L133.145178,76.5477125 L108.000897,71.3153956 Z M123.56879,86 C123.540736,86 123.512682,85.9908615 123.491641,85.9705536 L107.538008,71.2016717 C107.498933,71.1671483 107.488913,71.109271 107.51296,71.0625629 C107.536004,71.0158549 107.587102,70.9935162 107.6382,71.0016394 L133.407682,76.3649418 C133.450765,76.373065 133.48483,76.4055575 133.495851,76.4471886 C133.506872,76.4888197 133.495851,76.533497 133.46379,76.5639587 L123.64694,85.9685228 C123.625899,85.9908615 123.596843,86 123.56879,86 L123.56879,86 Z"
          id="Fill-83"
          fill="#64C5EC"
        ></path>
        <path
          d="M108.641862,71.712939 L123.530892,85.3770061 L132.690934,76.6749957 L108.641862,71.712939 Z M123.533863,86 C123.467501,86 123.404109,85.9751201 123.355575,85.9303361 L107.586001,71.4571732 C107.498838,71.3785525 107.474076,71.2481816 107.529543,71.1416954 C107.580058,71.0401853 107.690993,70.9854494 107.808861,71.0033629 L133.29117,76.2599982 C133.387247,76.2799022 133.464505,76.3515564 133.491249,76.4461002 C133.516011,76.54562 133.487287,76.646135 133.416962,76.7118181 L123.714132,85.9283458 C123.66857,85.9741249 123.603198,86 123.533863,86 L123.533863,86 Z"
          id="Fill-85"
          fill="#66C6EB"
        ></path>
        <path
          d="M167.555722,113.310255 L143.876177,156.645907 L188.197835,136.836332 L167.555722,113.310255 Z M143.614796,157 C143.583751,157 143.552705,156.987249 143.531675,156.963708 C143.497625,156.928397 143.490615,156.876411 143.512647,156.833253 L167.431541,113.059153 C167.449567,113.026784 167.482615,113.004224 167.520671,113.001282 C167.559728,112.995397 167.59578,113.01011 167.619815,113.038555 L188.472235,136.802983 C188.495269,136.830447 188.505283,136.865758 188.497272,136.901069 C188.48926,136.9354 188.466226,136.964826 188.433178,136.978558 L143.662866,156.98921 C143.646843,156.996077 143.630819,157 143.614796,157 L143.614796,157 Z"
          id="Fill-87"
          fill="#64C5EC"
        ></path>
        <path
          d="M167.074926,113.228463 L143.86394,156.671927 L187.308436,136.813111 L167.074926,113.228463 Z M143.263072,157.5 C143.189456,157.5 143.118824,157.468113 143.070079,157.413306 C142.995468,157.335581 142.977561,157.214011 143.030286,157.113366 L166.791404,112.640541 C166.831197,112.563812 166.907798,112.511995 166.995341,112.50203 C167.077911,112.491069 167.166449,112.524949 167.223153,112.59271 L187.936163,136.735385 C187.989883,136.798164 188.011769,136.883861 187.993863,136.963579 C187.976951,137.04529 187.920246,137.114047 187.844641,137.147927 L143.373497,157.476085 C143.336689,157.492028 143.300875,157.5 143.263072,157.5 L143.263072,157.5 Z"
          id="Fill-89"
          fill="#66C6EB"
        ></path>
        <path
          d="M129.394197,145.925273 L143.671144,157.247557 L187.096612,136.85971 L129.394197,145.925273 Z M143.655314,157.5 C143.631569,157.5 143.606834,157.491857 143.587046,157.47557 L129.043952,145.943595 C129.008333,145.915094 128.992503,145.866234 129.003386,145.821445 C129.015259,145.776657 129.051867,145.742048 129.096389,145.734922 L187.870318,136.501403 C187.926713,136.492242 187.979151,136.528887 187.994981,136.583854 C188.011801,136.63984 187.985087,136.699897 187.933639,136.723309 L143.702805,157.489821 C143.686975,157.496946 143.672134,157.5 143.655314,157.5 L143.655314,157.5 Z"
          id="Fill-91"
          fill="#64C5EC"
        ></path>
        <path
          d="M129.903417,146.035713 L143.76429,156.926083 L185.926741,137.316595 L129.903417,146.035713 Z M143.729835,157.5 C143.673722,157.5 143.617609,157.479933 143.572325,157.444816 L129.102086,146.077854 C129.018408,146.01063 128.981984,145.902268 129.008564,145.799926 C129.035144,145.696581 129.118821,145.620326 129.222187,145.604273 L187.700696,136.502877 C187.824735,136.483814 187.949759,136.561072 187.989136,136.687494 C188.026545,136.815923 187.964525,136.952378 187.846393,137.006559 L143.838123,157.47592 C143.801698,157.491973 143.766259,157.5 143.729835,157.5 L143.729835,157.5 Z"
          id="Fill-93"
          fill="#66C6EB"
        ></path>
        <path
          d="M134.510323,101.656322 L166.875252,111.938445 L143.925739,98.7455097 L134.510323,101.656322 Z M167.88487,112.5 C167.872839,112.5 167.862813,112.497939 167.850782,112.494848 L134.081211,101.768633 C134.032083,101.752147 134,101.706811 134,101.654261 C134,101.602743 134.033086,101.556376 134.081211,101.54195 L143.905687,98.5044017 C143.935765,98.4961587 143.968851,98.4992498 143.994919,98.5157358 L167.941016,112.278469 C167.993151,112.30835 168.013203,112.372233 167.991146,112.428904 C167.972096,112.47321 167.929987,112.5 167.88487,112.5 L167.88487,112.5 Z"
          id="Fill-95"
          fill="#64C5EC"
        ></path>
        <path
          d="M135.165261,101.741557 L165.390571,111.230802 L143.95732,99.0549708 L135.165261,101.741557 Z M167.694293,112.5 L167.618859,112.488911 L134.1866,101.993582 C134.074442,101.957291 134,101.854464 134,101.737525 C134,101.619577 134.074442,101.516751 134.1866,101.482476 L143.912655,98.5116049 C143.985112,98.4894267 144.060546,98.4995077 144.121092,98.5378155 L167.822333,111.999982 C167.937469,112.065509 167.986104,112.206643 167.939454,112.331647 L168,112.366931 L167.933499,112.345761 C167.888834,112.440522 167.796526,112.5 167.694293,112.5 L167.694293,112.5 Z"
          id="Fill-97"
          fill="#66C6EB"
        ></path>
        <path
          d="M201.499989,182.235159 L184.236359,196.518203 L214.95888,184.441491 L201.499989,182.235159 Z M183.613766,197 C183.574236,197 183.536683,196.980132 183.51593,196.94437 C183.487271,196.895693 183.498141,196.83311 183.541624,196.798341 L201.396223,182.026546 C201.419941,182.005684 201.452553,181.99575 201.486153,182.001711 L215.404578,184.283541 C215.455966,184.291488 215.49352,184.332217 215.499449,184.383874 C215.50439,184.434537 215.475731,184.483213 215.428295,184.502088 L183.655272,196.992053 C183.641437,196.99702 183.627601,197 183.613766,197 L183.613766,197 Z"
          id="Fill-99"
          fill="#64C5EC"
        ></path>
        <path
          d="M201.587072,182.030826 L184.73465,195.410964 L214.726489,184.097851 L201.587072,182.030826 Z M183.267422,196.5 C183.172502,196.5 183.085659,196.452269 183.037189,196.373368 C182.969533,196.263295 182.996798,196.121078 183.097777,196.042176 L201.340683,181.558391 C201.399251,181.511635 201.479025,181.492153 201.555769,181.502868 L215.776672,183.739385 C215.895828,183.758867 215.985699,183.850432 215.998827,183.965375 C216.009934,184.08324 215.941269,184.192338 215.831201,184.234224 L183.365372,196.481492 C183.331039,196.494155 183.299735,196.5 183.267422,196.5 L183.267422,196.5 Z"
          id="Fill-101"
          fill="#66C6EB"
        ></path>
        <path
          d="M154.52626,285.915179 L170.981961,290.269543 L213.766584,268.065978 L154.52626,285.915179 Z M170.995994,290.5 C170.98597,290.5 170.973942,290.499028 170.963919,290.49611 L154.085223,286.029921 C154.035105,286.01728 154.001025,285.974494 154.000022,285.92393 C153.99902,285.874338 154.032098,285.830581 154.081213,285.815995 L214.851134,267.504909 C214.905262,267.488378 214.96841,267.514633 214.990462,267.568114 C215.014519,267.621596 214.991464,267.682856 214.939342,267.710083 L171.049119,290.487359 C171.033081,290.49611 171.014036,290.5 170.995994,290.5 L170.995994,290.5 Z"
          id="Fill-103"
          fill="#64C5EC"
        ></path>
        <path
          d="M213.604905,268.12829 L213.665748,268.267514 L214.393865,267.892091 L213.604905,268.12829 Z M155.208913,285.819224 L171.030001,289.973833 L212.168619,268.78888 L155.208913,285.819224 Z M171.062916,290.5 L170.97614,290.484637 L154.195534,286.079427 C154.083823,286.052543 154.003032,285.952686 154.000039,285.839387 C153.998045,285.727049 154.071854,285.628152 154.18556,285.592626 L214.657184,267.510908 C214.794828,267.472502 214.92948,267.538753 214.979351,267.658773 C215.032214,267.773992 214.980348,267.916095 214.860658,267.978506 L171.186596,290.469275 C171.148694,290.489438 171.105805,290.5 171.062916,290.5 L171.062916,290.5 Z"
          id="Fill-105"
          fill="#66C6EB"
        ></path>
      </g>
    </g>
  </svg>
)

export default LineArt3
