import { Link, navigate } from "gatsby"
import React from "react"
import styled from "styled-components"

import colors from "../../styles/colors"

const CardTitle = styled.h3`
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 15px;
`
const CardLink = styled.div`
  font-size: 12px;
  letter-spacing: 1px;
  margin-top: auto;
  padding-top: 10px;

  color: ${colors.supportGrey};
`

const TopSection = styled.div`
  position: relative;
  border-radius: 10px 10px 0 0;
  background-color: ${props => props.color || colors.supportBlue};
  height: 240px;
  width: 100%;

  z-index: 1;

  overflow: hidden;
`
const BottomSection = styled.div`
  border-radius: 0 0 10px 10px;
  background-color: ${colors.white};

  padding: 30px 25px 20px;

  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const CardWrapper = styled.div`
  height: 450px;
  width: 320px;
  display: flex;

  flex-direction: column;

  transform: translate3d(0, 0, 0);
  transition: transform 0.2s linear, box-shadow 0.2s linear;
  color: ${colors.offBlack};

  border-radius: 10px;
  box-shadow: #00000018 0 0px 10px 0;

  overflow-y: hidden;

  cursor: pointer;

  text-decoration: none;

  :hover {
    transform: translate3d(0, -10px, 0);
    box-shadow: #00000040 0 10px 20px 0;
    > .bottom {
      *:not(.skipUnderline) {
        text-decoration: underline;
        color: ${colors.blue};
      }
    }
    .description {
      color: ${colors.offBlack};
    }

    .link {
      color: ${colors.blue};
    }
  }

  &.office {
    > .bottom > .link {
      color: ${colors.blue};
      font-size: 16px;
      letter-spacing: 0.3px;
      line-height: 24px;
    }
  }

  &.product {
    height: 530px;
    > .top {
      height: 200px;
    }
  }
`

const navTo = (link, noLink) => {
  if (noLink) {
    // No link
    return () => null
  }
  return e => {
    try {
      e.preventDefault()
      e.stopPropagation()
    } catch (e) {}
    // alert(link)
    navigate(link)
  }
}

/**
 *
 * @param {*} is_link Whether the gatsby Link componenent should be used
 * @param {*} has_bottom Whether it should only be linked at the bottom of the card (i.e. not
 *                   the whole card - the mailto links on the office card)
 * @param {*} is_bottom Whether we're getting the element for the link at the bottom
 */
const linkElement = (is_link, has_bottom, is_bottom, address) => {
  const link = is_link ? Link : "a"
  if (is_bottom) {
    if (has_bottom) {
      return { link, address }
    }
  } else {
    if (!has_bottom) {
      return { link, address }
    }
  }
  return { link: undefined, address: undefined }
}

const getLinkInfo = (is_link, has_bottom, address) => {
  return {
    card: linkElement(is_link, has_bottom, false, address),
    bottom: linkElement(is_link, has_bottom, true, address),
  }
}

export const Card = ({
  title,
  color,
  content,
  image,
  linkText = "View work",
  linkAddress,
  useLink = true,
  linkBottom = false,
  underlineTitle = true,
  cardType,
}) => {
  const link_info = getLinkInfo(useLink, linkBottom, linkAddress)
  // console.log({ useLink, linkBottom, linkAddress, link_info })
  return (
    <CardWrapper
      as={link_info.card.link}
      to={link_info.card.address}
      href={link_info.card.address}
      className={cardType}
      onClick={navTo(linkAddress, linkBottom || useLink)}
    >
      <TopSection color={color} className="top">
        {image}
      </TopSection>
      <BottomSection className="bottom">
        <CardTitle className={`title${!underlineTitle && " skipUnderline"}`}>
          {title}
        </CardTitle>
        {content}
        {linkBottom ? (
          <CardLink
            className="link"
            as={link_info.bottom.link}
            target={!useLink && "_blank"}
            href={linkAddress}
            to={linkAddress}
          >
            {linkText}
          </CardLink>
        ) : (
          <CardLink className="link">{linkText}</CardLink>
        )}
      </BottomSection>
    </CardWrapper>
  )
}

export default Card
