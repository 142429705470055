export const isIE = () => {
  if (
    typeof navigator !== "undefined" &&
    (navigator.userAgent.indexOf("MSIE") !== -1 ||
      navigator.appVersion.indexOf("Trident/") > -1)
  ) {
    return true
  }
  return false
}

export default isIE
