import _ from "lodash"
import React from "react"
import styled from "styled-components"

import below from "../../styles/media"
import ShowMore from "../ShowMore"

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

export const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;

  justify-content: flex-start;
  align-items: center;

  margin-right: -40px;

  max-width: 1080px;

  ${below.tablet`
    justify-content: center;
    margin-right:0;
    
  `}

  > * {
    margin-right: 40px;
    margin-bottom: ${props => (props.compact ? "20px" : "40px")};

    ${below.tablet`
    margin: 0 10px ${props => (props.compact ? "20px" : "40px")};
  `}
  }

  > div:last-child {
    margin-right: auto;
    ${below.tablet`
      margin-right: 10px;
    `}
  }
`

export class ItemRows extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showing: Math.max(props.child_limit, 0),
    }
    _.bindAll(this, ["handleShowMore"])
  }
  componentDidUpdate(prev_props) {
    if (
      prev_props.child_limit !== this.props.child_limit &&
      this.state.showing !== this.props.child_limit
    ) {
      this.setState({
        showing: Math.max(this.props.child_limit, 0),
      })
    }
  }
  handleShowMore() {
    /* Show more of the children */
    const { show_more_increment, children } = this.props
    const { showing } = this.state

    if (show_more_increment > 0) {
      this.setState({
        showing: showing + show_more_increment,
      })
    } else {
      this.setState({
        showing: _.size(children),
      })
    }
  }
  /**
   * Show More button is shown if not all children are being shown
   */
  get show_more_button_visible() {
    return (
      this.state.showing < _.size(this.props.children) &&
      this.props.child_limit > 0
    )
  }
  renderChildren() {
    const { children, child_limit } = this.props

    if (child_limit > 0) {
      return _.take(children, this.state.showing)
    }
    return children
  }

  renderShowMore() {
    if (this.show_more_button_visible) {
      return <ShowMore onClick={this.handleShowMore} />
    }
    return null
  }
  render() {
    const { compact } = this.props
    return (
      <SectionWrapper>
        <ContentsWrapper compact={compact}>
          {this.renderChildren()}
        </ContentsWrapper>
        {this.renderShowMore()}
      </SectionWrapper>
    )
  }
}

export default ItemRows
