import React from 'react';

export const Quote = () => (
	<svg viewBox="0 0 82 63" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Testimonial</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Component/Desktop/Testimonial" transform="translate(-679.000000, -25.000000)" fill="#59CFF5">
            <path d="M679,65.6875 C679,49.28125 688.27381,34.625 702.297619,25 L714.738095,34.84375 C707.5,38.5625 699.130952,47.75 697.095238,55.625 C698,55.1875 699.809524,54.96875 701.619048,54.96875 C710.214286,54.96875 717,61.3125 717,70.71875 C717,80.125 708.857143,88 699.130952,88 C688.5,88 679,79.90625 679,65.6875 Z M723,65.6875 C723,49.28125 732.329341,34.625 746.437126,25 L758.952096,34.84375 C751.670659,38.5625 743.023952,47.75 741.203593,55.625 C741.886228,55.1875 743.934132,54.96875 745.754491,54.96875 C754.401198,54.96875 761,61.3125 761,70.71875 C761,80.125 753.035928,88 743.251497,88 C732.556886,88 723,79.90625 723,65.6875 Z" id="Quote-Icon"></path>
        </g>
    </g>
</svg>
);

export default Quote;