import React from "react"
import styled from "styled-components"

import colors from "../../styles/colors"

const Name = styled.h3`
  font-size: 22px;
  font-weight: 600;
  color: ${colors.offBlack};
  margin: 0 auto 20px;
`

const Position = styled.h4`
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
  margin: 0 auto 15px;
  color: ${colors.offBlack};
`

const TopSection = styled.div`
  position: relative;
  border-radius: 10px 10px 0 0;
  height: 240px;
  width: 100%;

  z-index: 1;

  overflow: hidden;

  > img {
    transition: filter 0.4s ease 0.1s;
    filter: grayscale();
  }
`
const BottomSection = styled.div`
  border-radius: 0 0 10px 10px;
  background-color: ${colors.white};

  padding: 30px 40px 20px;

  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const CardWrapper = styled.div`
  height: 400px;
  width: 320px;

  display: flex;
  flex-direction: column;

  color: ${colors.offBlack};

  border-radius: 10px;

  &:hover > ${TopSection} > img {
    filter: none;
  }
`

export const EmployeeCard = ({ name, position, image }) => {
  return (
    <CardWrapper>
      <TopSection>
        <img src={image} alt={name} />
      </TopSection>
      <BottomSection>
        <Name>{name}</Name>
        <Position>{position}</Position>
      </BottomSection>
    </CardWrapper>
  )
}

export default EmployeeCard
