import React from "react"
import styled from "styled-components"

import colors from "../../styles/colors"

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 13px 0;

  background-color: ${colors.lightGrey};

  h4 {
    color: ${colors.strongBlack};
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 35px;
    text-align: center;
    margin: 0;
    max-width: 30em;
  }
  p {
    color: ${colors.offBlack};
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 23px;
    text-align: center;

    max-width: 550px;
  }
`

export const EmptyPage = ({ title, description }) => (
  <ContentWrapper>
    <h4>{title}</h4>
    <p>{description}</p>
  </ContentWrapper>
)

export default EmptyPage
