import { navigate } from "gatsby"
import React from "react"
import styled from "styled-components"

import colors from "../../styles/colors"

export const TagWrapper = styled.div`
  box-sizing: border-box;
  color: ${colors.darkGrey};
  background-color: ${colors.lightGrey};
  font-size: 10px;
  letter-spacing: 1px;

  text-decoration: none;

  user-select: none;

  padding: 10px 8px;
  min-width: 55px;
  text-align: center;

  border-radius: 2px;

  border: 1px solid ${colors.lightGrey};

  transition: border 0.2s linear;

  :hover {
    background-color: ${colors.tag.hover};
  }
`

export const Tag = ({ title, link }) => (
  <TagWrapper
    className="skipUnderline"
    onClick={e => {
      // Don't let click handlers bubble up - i.e. don't go to actual card location
      try {
        e.preventDefault()
        e.stopPropagation()
      } catch (e) {}
      navigate(link)
    }}
  >
    {title}
  </TagWrapper>
)

export default Tag
