import { graphql } from "gatsby"
import _ from "lodash"
import React from "react"
import { Helmet } from "react-helmet"

import { CaseStudyCard } from "../components/Card/types"
import EmptyPage from "../components/EmptyPage"
import ItemRows from "../components/ItemRows"
import { PageContentsWrapper, PageLayout } from "../components/PageLayout"
import SectionTitle from "../components/SectionTitle"
import Spacer from "../components/Spacer"
import {
  createStaticContent,
  makeStaticPageTitle,
  transformToArray,
  transformToMap,
} from "../data/transform"

export const query = graphql`
  query($client: String!, $recordId: String!) {
    locations: allAirtable(filter: { table: { eq: "Locations" } }) {
      ...LocationsFragment
    }
    client: airtable(
      table: { eq: "Clients" }
      data: { client: { eq: $client } }
    ) {
      data {
        client
        name
        quotes
        weight
        logo {
          url
        }
        similarCases
        includeThe
      }
    }
    relatedCases: allAirtable(
      filter: {
        table: { eq: "CaseStudies" }
        data: { clientID: { eq: $recordId }, Disable: { ne: true } }
      }
    ) {
      edges {
        node {
          data {
            case
            name
            productID
            clientID
            sportID
            cardImage {
              url
            }
            weight
          }
        }
      }
    }
    sports: allAirtable(filter: { table: { eq: "Sports" } }) {
      ...SportsFragment
    }
    clients: allAirtable(filter: { table: { eq: "Clients" } }) {
      ...ClientsFragment
    }
    products: allAirtable(filter: { table: { eq: "Products" } }) {
      ...ProductsFragment
    }
    caseStudies: allAirtable(
      filter: { table: { eq: "CaseStudies" }, data: { Disable: { ne: true } } }
    ) {
      ...CaseStudiesFragment
    }
    static_content_all: allAirtable(
      filter: { table: { eq: "StaticContent" }, data: { page: { eq: "all" } } }
    ) {
      ...StaticContentFragment
    }
    static_content_client: allAirtable(
      filter: {
        table: { eq: "StaticContent" }
        data: { page: { eq: "client" } }
      }
    ) {
      ...StaticContentFragment
    }
  }
`

export default props => {
  const locations = transformToArray(
    props.data,
    "locations",
    record => record.inFooter
  )
  const cases = transformToArray(props.data, "relatedCases")
  const client = _.get(props, "data.client", {})

  const client_name = _.get(client, "data.name", "")
  const include_the = _.get(client, "data.includeThe", false) ? " the" : ""

  const sportMap = transformToMap(props.data, "sports")
  const clientMap = transformToMap(props.data, "clients")
  const productMap = transformToMap(props.data, "products")
  const content = createStaticContent(props.data, "client")

  // const caseMap = transformToMap(props.data, "caseStudies")
  // const similar_cases = _.map(
  //   _.get(client, "data.similarCases", []),
  //   recordId => _.get(caseMap, recordId)
  // )

  return (
    <PageLayout locations={locations} static_content={content}>
      <Helmet>
        <title>
          {makeStaticPageTitle(
            content,
            `Our Work with${include_the} ${client_name}`
          )}
        </title>
      </Helmet>
      <PageContentsWrapper>
        <SectionTitle title={client_name} bg_icon="lineart1" />
        <Spacer height="20" />
        {!_.isEmpty(cases) ? (
          <ItemRows child_limit={0}>
            {_.map(cases, (caseStudy, i) => (
              <CaseStudyCard
                key={i}
                caseStudy={caseStudy}
                clientMap={clientMap}
                productMap={productMap}
                sportMap={sportMap}
              />
            ))}
          </ItemRows>
        ) : (
          <EmptyPage
            title={
              `We're hard at work documenting more of our success stories. ` +
              `Check back soon to see all the exciting things we've been doing with${include_the} ${client_name}.`
            }
            description=""
          />
        )}

        {/* <Spacer height="20" />

        <SubTitle title="Similar Projects" />
        <ItemRows child_limit={0}>
          {_.map(similar_cases, (caseStudy, i) => (
            <CaseStudyCard
              key={i}
              caseStudy={caseStudy}
              clientMap={clientMap}
              productMap={productMap}
              sportMap={sportMap}
            />
          ))}
        </ItemRows> */}
      </PageContentsWrapper>
    </PageLayout>
  )
}
