import React from "react"

export const LineArt4 = () => (
  <svg
    viewBox="0 0 400 400"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>LineArt/4</title>
    <defs>
      <polygon
        id="path-1"
        points="0.0039488451 0.372113624 33.2390411 0.372113624 33.2390411 28 0.0039488451 28"
      ></polygon>
    </defs>
    <g
      id="SiteAssets/LineArt/4"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.701497396"
    >
      <g
        id="4"
        transform="translate(200.500000, 200.000000) scale(-1, 1) rotate(90.000000) translate(-200.500000, -200.000000) translate(51.500000, 37.500000)"
      >
        <path
          d="M0.573422847,172.762454 L87.6382769,238.004876 L67.3826971,185.270376 L0.573422847,172.762454 Z M87.8861094,238.4471 C87.8612266,238.4471 87.8373391,238.439185 87.8164376,238.424346 L0.0459078693,172.651651 C0.00310948433,172.619003 -0.0118201848,172.563602 0.0100766633,172.515125 C0.0309782001,172.465659 0.0817390753,172.439937 0.136481196,172.448841 L67.4882001,185.058663 C67.5270172,185.065588 67.5598625,185.093289 67.5737968,185.128904 L87.9926077,238.292767 C88.0105233,238.340254 87.9955936,238.393677 87.9537906,238.424346 C87.9338843,238.439185 87.9099969,238.4471 87.8861094,238.4471 L87.8861094,238.4471 Z"
          id="Fill-1"
          fill="#64C5EC"
        ></path>
        <path
          d="M1.31692478,173.170132 L87.1672941,237.431672 L67.1943639,185.490202 L1.31692478,173.170132 Z M87.7376658,238.4471 C87.6801327,238.4471 87.6245834,238.428387 87.5789537,238.393916 L0.104760851,172.915044 C0.0075496692,172.842162 -0.0261766593,172.714125 0.0214369809,172.601847 C0.0700425721,172.492524 0.185108869,172.432445 0.307118823,172.450173 L67.4363999,185.003663 C67.5266675,185.021392 67.6000718,185.08344 67.6328062,185.166171 L87.9836696,238.092537 C88.0243396,238.206785 87.9876374,238.327928 87.8924101,238.39687 C87.8527321,238.427402 87.7971829,238.4471 87.7376658,238.4471 L87.7376658,238.4471 Z"
          id="Fill-3"
          fill="#66C6EB"
        ></path>
        <path
          d="M122.238331,198.642687 L136.743362,214.065485 L132.103026,170.123498 L122.238331,198.642687 Z M136.889127,214.5 C136.859201,214.5 136.831207,214.488013 136.80997,214.46404 L122.030786,198.750567 C122.001826,198.719601 121.992173,198.673653 122.006653,198.632698 L132.055725,169.577107 C132.073101,169.526164 132.118472,169.493201 132.173495,169.501192 C132.224658,169.508184 132.265202,169.550137 132.270994,169.603078 L136.999174,214.372143 C137.004966,214.420089 136.979867,214.468036 136.937393,214.489012 C136.920982,214.496004 136.904572,214.5 136.889127,214.5 L136.889127,214.5 Z"
          id="Fill-26"
          fill="#64C5EC"
        ></path>
        <path
          d="M122.539447,198.562029 L136.419715,213.50673 L131.979204,170.927083 L122.539447,198.562029 Z M136.748326,214.5 C136.680142,214.5 136.615745,214.471224 136.567448,214.419625 L122.070679,198.810098 C122.005336,198.739647 121.982607,198.633473 122.013859,198.540199 L131.872192,169.675821 C131.911019,169.558732 132.017084,169.483319 132.142089,169.503164 C132.255729,169.517056 132.345695,169.613307 132.358953,169.735357 L136.998336,214.209263 C137.010647,214.319405 136.953827,214.427564 136.855338,214.475193 C136.819352,214.492062 136.784312,214.5 136.748326,214.5 L136.748326,214.5 Z"
          id="Fill-28"
          fill="#66C6EB"
        ></path>
        <polygon
          id="Fill-44"
          fill="#66C6EB"
          points="59.6949651 205.315734 58.7166019 205.973882 59.7739137 205.452765"
        ></polygon>
        <path
          d="M98.110821,64.6363931 L119.235304,95.6002757 L122.253376,82.8508007 L98.110821,64.6363931 Z M119.286869,96 C119.248448,96 119.21306,95.981692 119.191828,95.9501616 L97.5203506,64.182762 C97.487996,64.1349578 97.4950736,64.071897 97.5355168,64.0322297 C97.576971,63.9925624 97.640669,63.9895111 97.6861676,64.0230757 L122.45357,82.7094224 C122.49098,82.7368843 122.507157,82.7846885 122.497046,82.8304585 L119.401121,95.9104943 C119.389999,95.9552472 119.3536,95.9898289 119.308102,95.9979658 C119.301024,96 119.293947,96 119.286869,96 L119.286869,96 Z"
          id="Fill-50"
          fill="#64C5EC"
        ></path>
        <path
          d="M98.8926974,65.4536925 L119.057555,95.0871774 L121.937535,82.8849764 L98.8926974,65.4536925 Z M119.17643,96 C119.088522,96 119.007607,95.9576837 118.95766,95.8851415 L97.5461093,64.4179512 C97.4731858,64.3101455 97.4881701,64.1660686 97.5810727,64.0743834 C97.6759732,63.9847132 97.8208213,63.9746379 97.9227144,64.0522177 L122.394057,82.5625667 C122.47697,82.6240261 122.515929,82.7318318 122.493952,82.8356074 L119.433161,95.7954713 C119.409186,95.8982394 119.327272,95.9768268 119.22438,95.9959699 L119.17643,96 Z"
          id="Fill-52"
          fill="#66C6EB"
        ></path>
        <path
          d="M73.9130314,49.2865729 L57.8529285,85.6360124 L97.1622792,65.3345792 L73.9130314,49.2865729 Z M57.6164235,86 C57.5881238,86 57.5588133,85.9891494 57.5375885,85.9694211 C57.5012032,85.9368694 57.4900854,85.8855757 57.5092888,85.8421734 L73.7563722,49.0685749 C73.770522,49.037996 73.7988218,49.0133356 73.8311643,49.0044578 C73.8655283,48.9945937 73.9019137,49.0014986 73.9312241,49.0212269 L97.4503302,65.2556659 C97.4826727,65.277367 97.5018761,65.3158373 97.4998547,65.3552939 C97.4968226,65.3947506 97.4735764,65.4302615 97.437191,65.448017 L57.6710016,85.9871766 C57.6528089,85.9950679 57.6346162,86 57.6164235,86 L57.6164235,86 Z"
          id="Fill-54"
          fill="#64C5EC"
        ></path>
        <path
          d="M74.0051995,49.6562536 L58.3098289,85.168201 L96.7272252,65.3341976 L74.0051995,49.6562536 Z M57.7671564,86 C57.6979431,86 57.6337453,85.9755353 57.5845938,85.9295417 C57.5043465,85.8581049 57.477263,85.7406744 57.520396,85.6398799 L73.6470959,49.1571742 C73.6822041,49.0828015 73.7443958,49.0309364 73.8196276,49.0103861 C73.8928533,48.9878786 73.977113,49.0025574 74.0453232,49.048551 L97.3882624,65.1541375 C97.4614881,65.2040455 97.504621,65.2911397 97.4996056,65.3801912 C97.4925839,65.4731569 97.4374139,65.5543797 97.3561635,65.5954803 L57.8915398,85.9706424 C57.8474037,85.9911927 57.806277,86 57.7671564,86 L57.7671564,86 Z"
          id="Fill-56"
          fill="#66C6EB"
        ></path>
        <path
          d="M196.923748,131.247169 L186.028341,144.563234 L213.875447,132.229893 L196.923748,131.247169 Z M185.616828,145 C185.580068,145 185.544328,144.980918 185.522885,144.950175 C185.49123,144.90459 185.492251,144.840983 185.526969,144.798579 L196.782832,131.043628 C196.807339,131.013945 196.840015,130.994863 196.878818,131.001223 L214.389073,132.01575 C214.443193,132.019991 214.488123,132.060275 214.498334,132.116461 C214.507524,132.171587 214.477911,132.226713 214.428897,132.250035 L185.662779,144.989399 C185.648483,144.99682 185.632145,145 185.616828,145 L185.616828,145 Z"
          id="Fill-58"
          fill="#64C5EC"
        ></path>
        <path
          d="M197.023735,131.556795 L186.707131,144.015264 L213.074593,132.476228 L197.023735,131.556795 Z M185.767421,145 C185.682544,145 185.602719,144.957501 185.552197,144.888051 C185.478435,144.783358 185.483487,144.643422 185.563312,144.547021 L196.702415,131.097597 C196.763042,131.022965 196.854992,130.988758 196.934817,131.00327 L214.247715,131.993189 C214.371999,132.001482 214.474054,132.094772 214.496283,132.21916 C214.516492,132.344584 214.452834,132.467936 214.340676,132.519764 L185.872507,144.978232 C185.844215,144.991707 185.805818,145 185.767421,145 L185.767421,145 Z"
          id="Fill-60"
          fill="#66C6EB"
        ></path>
        <path
          d="M127.798912,23.432295 L107.962627,51.5846599 L129.268266,40.9446523 L127.798912,23.432295 Z M107.612454,52 C107.581066,52 107.548697,51.9861882 107.527118,51.9605378 C107.493768,51.920089 107.490825,51.8628687 107.520252,51.8204468 L127.794008,23.0475381 C127.81951,23.0090624 127.868554,22.9913044 127.914655,23.0041296 C127.960757,23.0169549 127.993126,23.0564171 127.99803,23.1037718 L129.499753,41.0018726 C129.502696,41.0482407 129.479155,41.0926357 129.436977,41.1133534 L107.662479,51.9881613 C107.646785,51.9960538 107.629129,52 107.612454,52 L107.612454,52 Z"
          id="Fill-62"
          fill="#64C5EC"
        ></path>
        <path
          d="M127.564596,23.9863645 L108.553867,51.0527026 L128.972905,40.8228671 L127.564596,23.9863645 Z M107.756309,52 C107.68178,52 107.611122,51.9667958 107.561759,51.9091766 C107.485294,51.8193299 107.479487,51.6884661 107.546272,51.5917832 L127.552981,23.109382 C127.611056,23.0214884 127.720429,22.9794948 127.829803,23.0097693 C127.932402,23.0371139 128.005963,23.1259841 128.017578,23.2334096 L129.499449,40.9547074 C129.506224,41.0640861 129.450085,41.1617456 129.356198,41.2086222 L107.869554,51.9726553 C107.835678,51.990234 107.795025,52 107.756309,52 L107.756309,52 Z"
          id="Fill-64"
          fill="#66C6EB"
        ></path>
        <path
          d="M128.877954,319.636405 L145.257926,324.720957 L145.257926,307.357488 L128.877954,319.636405 Z M145.378963,325 C145.366333,325 145.353703,324.99897 145.342126,324.994852 L128.583255,319.792916 C128.541156,319.78056 128.508528,319.743491 128.502213,319.700245 C128.493793,319.656998 128.509581,319.612722 128.546418,319.58595 L145.305289,307.023872 C145.342126,306.99813 145.389488,306.991952 145.43264,307.011516 C145.473688,307.03211 145.5,307.072267 145.5,307.118603 L145.5,324.882617 C145.5,324.920715 145.481055,324.955724 145.450533,324.977347 C145.429483,324.993822 145.404223,325 145.378963,325 L145.378963,325 Z"
          id="Fill-66"
          fill="#64C5EC"
        ></path>
        <path
          d="M129.356887,319.518135 L144.952391,324.365375 L144.952391,307.812491 L129.356887,319.518135 Z M145.225162,325 L145.139404,324.98583 L128.690457,319.873404 C128.594367,319.843039 128.521008,319.760042 128.504477,319.662874 C128.485878,319.565707 128.526174,319.462466 128.607799,319.403761 L145.059846,307.055395 C145.142504,306.993653 145.254092,306.982519 145.346049,307.027054 C145.443173,307.078674 145.5,307.170781 145.5,307.268961 L145.5,324.730765 C145.5,324.815787 145.457638,324.89676 145.388412,324.947368 C145.34295,324.980769 145.286122,325 145.225162,325 L145.225162,325 Z"
          id="Fill-68"
          fill="#66C6EB"
        ></path>
        <path
          d="M66.5550448,12.2720643 L74.7549041,12.2720643 L79.5243788,4.94925656 L66.5550448,12.2720643 Z M74.8199988,12.5 L66.119014,12.5 C66.0652851,12.5 66.0187889,12.466158 66.0043234,12.4163904 C65.989858,12.3666228 66.0125894,12.3138691 66.0590856,12.2879899 L79.8219543,4.51627826 C79.8674172,4.4884084 79.9283789,4.49736657 79.9666091,4.53518996 C80.0048393,4.572018 80.0110388,4.63074379 79.9810746,4.6745393 L74.9202239,12.446251 C74.8985257,12.480093 74.8602955,12.5 74.8199988,12.5 L74.8199988,12.5 Z"
          id="Fill-70"
          fill="#64C5EC"
        ></path>
        <path
          d="M67.2526792,11.9924876 L74.6327403,11.9924876 L78.9253074,5.49939911 L67.2526792,11.9924876 Z M74.7803416,12.5 L66.2669861,12.5 C66.146681,12.5 66.0435623,12.4261277 66.0102004,12.3148395 C65.9768385,12.202592 66.0273868,12.0855475 66.1315165,12.0270253 L79.5976006,4.5361809 C79.6966754,4.47573992 79.8361889,4.49204939 79.9241431,4.57743427 C80.0110863,4.66377853 80.0252398,4.79521369 79.956494,4.89307052 L75.0067982,12.3810368 C74.9602937,12.4539497 74.8723396,12.5 74.7803416,12.5 L74.7803416,12.5 Z"
          id="Fill-72"
          fill="#66C6EB"
        ></path>
        <path
          d="M230.289093,176.309806 L260.524325,190.766049 L282.897993,182.881889 L230.289093,176.309806 Z M260.516251,191 C260.499095,191 260.481939,190.996101 260.464783,190.988302 L229.564497,176.213302 C229.513028,176.188932 229.488808,176.133368 229.504955,176.080729 C229.521102,176.02809 229.575598,175.989098 229.631103,176.002745 L283.39855,182.718123 C283.452037,182.724947 283.493413,182.764913 283.499468,182.816578 C283.504514,182.868242 283.47323,182.916007 283.42277,182.933553 L260.556619,190.993176 C260.543499,190.997076 260.53038,191 260.516251,191 L260.516251,191 Z"
          id="Fill-74"
          fill="#64C5EC"
        ></path>
        <path
          d="M231.309505,176.700762 L260.510893,190.471284 L282.119399,182.96122 L231.309505,176.700762 Z M260.493833,191 C260.449676,191 260.409534,190.990439 260.372403,190.972273 L229.648624,176.482774 C229.532212,176.43019 229.474006,176.303986 229.511137,176.184475 C229.552283,176.054447 229.679734,175.974136 229.817221,176.007599 L283.267463,182.592171 C283.389897,182.607468 283.484231,182.698296 283.498281,182.814939 C283.51233,182.932538 283.439071,183.041531 283.322659,183.080731 L260.58616,190.983747 C260.559064,190.994263 260.52695,191 260.493833,191 L260.493833,191 Z"
          id="Fill-76"
          fill="#66C6EB"
        ></path>
        <path
          d="M236.537714,249.81271 L269.329483,276.773544 L252.933097,252.783084 L236.537714,249.81271 Z M269.884918,277.5 C269.859854,277.5 269.833786,277.491803 269.812732,277.474384 L236.044439,249.708198 C236.00233,249.674386 235.988294,249.617007 236.010351,249.567826 C236.031405,249.518644 236.085545,249.494053 236.135675,249.501225 L253.01932,252.559717 C253.0504,252.565865 253.076468,252.583283 253.094514,252.608899 L269.979162,277.315568 C270.012248,277.363725 270.00523,277.428277 269.963121,277.469261 C269.941064,277.488729 269.912991,277.5 269.884918,277.5 L269.884918,277.5 Z"
          id="Fill-78"
          fill="#64C5EC"
        ></path>
        <g id="Group-82" transform="translate(236.000000, 249.500000)">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"></use>
          </mask>
          <g id="Clip-81"></g>
          <path
            d="M1.20505714,1.0771 L31.7399714,26.3651 L16.4720286,3.8631 L1.20505714,1.0771 Z M32.9814571,28.0001 C32.9231714,28.0001 32.8658571,27.9781 32.8202,27.9401 L0.101514286,0.8431 C0.00922857143,0.7671 -0.0218571429,0.6371 0.0267142857,0.5261 C0.0733428571,0.4221 0.179228571,0.3571 0.300657143,0.3751 L16.6663143,3.3611 C16.7323714,3.3721 16.7926,3.4111 16.8324286,3.4711 L33.1932286,27.5831 C33.2660857,27.6931 33.2505429,27.8381 33.1572857,27.9281 C33.1067714,27.9741 33.0446,28.0001 32.9814571,28.0001 L32.9814571,28.0001 Z"
            id="Fill-80"
            fill="#66C6EB"
            mask="url(#mask-2)"
          ></path>
        </g>
        <path
          d="M190.000897,0.315395607 L205.567788,14.7248287 L215.145178,5.54771247 L190.000897,0.315395607 Z M205.56879,15 C205.540736,15 205.512682,14.9908615 205.491641,14.9705536 L189.538008,0.201671662 C189.498933,0.167148321 189.488913,0.109270956 189.51296,0.0625629076 C189.536004,0.0158548588 189.587102,-0.00648377323 189.6382,0.00163936569 L215.407682,5.36494184 C215.450765,5.37306498 215.48483,5.40555754 215.495851,5.44718862 C215.506872,5.48881971 215.495851,5.53349697 215.46379,5.56395875 L205.64694,14.9685228 C205.625899,14.9908615 205.596843,15 205.56879,15 L205.56879,15 Z"
          id="Fill-83"
          fill="#64C5EC"
        ></path>
        <path
          d="M190.641862,0.71293904 L205.530892,14.3770061 L214.690934,5.67499574 L190.641862,0.71293904 Z M205.533863,15 C205.467501,15 205.404109,14.9751201 205.355575,14.9303361 L189.586001,0.457173181 C189.498838,0.378552548 189.474076,0.248181623 189.529543,0.141695449 C189.580058,0.0401852637 189.690993,-0.0145506204 189.808861,0.00336294168 L215.29117,5.25999822 C215.387247,5.27990217 215.464505,5.35155642 215.491249,5.44610022 C215.516011,5.54562001 215.487287,5.646135 215.416962,5.71181806 L205.714132,14.9283458 C205.66857,14.9741249 205.603198,15 205.533863,15 L205.533863,15 Z"
          id="Fill-85"
          fill="#66C6EB"
        ></path>
        <path
          d="M249.555722,42.3102548 L225.876177,85.6459073 L270.197835,65.8363322 L249.555722,42.3102548 Z M225.614796,86 C225.583751,86 225.552705,85.9872487 225.531675,85.9637079 C225.497625,85.9283968 225.490615,85.8764109 225.512647,85.8332527 L249.431541,42.059153 C249.449567,42.0267844 249.482615,42.0042245 249.520671,42.0012819 C249.559728,41.9953967 249.59578,42.0101097 249.619815,42.0385548 L270.472235,65.8029827 C270.495269,65.830447 270.505283,65.8657581 270.497272,65.9010693 C270.48926,65.9353997 270.466226,65.9648256 270.433178,65.9785578 L225.662866,85.9892105 C225.646843,85.9960765 225.630819,86 225.614796,86 L225.614796,86 Z"
          id="Fill-87"
          fill="#64C5EC"
        ></path>
        <path
          d="M249.074926,42.2284627 L225.86394,85.6719265 L269.308436,65.8131108 L249.074926,42.2284627 Z M225.263072,86.5 C225.189456,86.5 225.118824,86.4681127 225.070079,86.4133064 C224.995468,86.3355811 224.977561,86.2140107 225.030286,86.1133664 L248.791404,41.6405405 C248.831197,41.5638117 248.907798,41.5119948 248.995341,41.50203 C249.077911,41.4910688 249.166449,41.524949 249.223153,41.5927096 L269.936163,65.7353855 C269.989883,65.7981636 270.011769,65.8838607 269.993863,65.963579 C269.976951,66.0452902 269.920246,66.1140472 269.844641,66.1479275 L225.373497,86.4760845 C225.336689,86.4920282 225.300875,86.5 225.263072,86.5 L225.263072,86.5 Z"
          id="Fill-89"
          fill="#66C6EB"
        ></path>
        <path
          d="M211.394197,74.9252728 L225.671144,86.2475567 L269.096612,65.8597096 L211.394197,74.9252728 Z M225.655314,86.5 C225.631569,86.5 225.606834,86.4918567 225.587046,86.47557 L211.043952,74.9435953 C211.008333,74.9150937 210.992503,74.8662337 211.003386,74.8214454 C211.015259,74.776657 211.051867,74.7420479 211.096389,74.7349225 L269.870318,65.501403 C269.926713,65.4922417 269.979151,65.5288867 269.994981,65.5838542 C270.011801,65.6398396 269.985087,65.6998967 269.933639,65.7233088 L225.702805,86.4898208 C225.686975,86.4969463 225.672134,86.5 225.655314,86.5 L225.655314,86.5 Z"
          id="Fill-91"
          fill="#64C5EC"
        ></path>
        <path
          d="M211.903417,75.0357134 L225.76429,85.9260833 L267.926741,66.3165947 L211.903417,75.0357134 Z M225.729835,86.5 C225.673722,86.5 225.617609,86.479933 225.572325,86.4448157 L211.102086,75.0778542 C211.018408,75.0106297 210.981984,74.9022678 211.008564,74.799926 C211.035144,74.6965809 211.118821,74.6203262 211.222187,74.6042726 L269.700696,65.5028772 C269.824735,65.4838135 269.949759,65.5610716 269.989136,65.6874938 C270.026545,65.8159227 269.964525,65.9523784 269.846393,66.0065593 L225.838123,86.4759196 C225.801698,86.4919732 225.766259,86.5 225.729835,86.5 L225.729835,86.5 Z"
          id="Fill-93"
          fill="#66C6EB"
        ></path>
        <path
          d="M216.510323,30.6563221 L248.875252,40.938445 L225.925739,27.7455097 L216.510323,30.6563221 Z M249.88487,41.5 C249.872839,41.5 249.862813,41.4979392 249.850782,41.4948481 L216.081211,30.7686331 C216.032083,30.7521471 216,30.7068106 216,30.6542614 C216,30.6027426 216.033086,30.5563757 216.081211,30.5419504 L225.905687,27.5044017 C225.935765,27.4961587 225.968851,27.4992498 225.994919,27.5157358 L249.941016,41.2784691 C249.993151,41.30835 250.013203,41.3722334 249.991146,41.4289041 C249.972096,41.4732102 249.929987,41.5 249.88487,41.5 L249.88487,41.5 Z"
          id="Fill-95"
          fill="#64C5EC"
        ></path>
        <path
          d="M217.165261,30.7415572 L247.390571,40.2308021 L225.95732,28.0549708 L217.165261,30.7415572 Z M249.694293,41.5 L249.618859,41.4889109 L216.1866,30.9935822 C216.074442,30.9572906 216,30.8544644 216,30.7375248 C216,30.6195771 216.074442,30.5167509 216.1866,30.4824755 L225.912655,27.5116049 C225.985112,27.4894267 226.060546,27.4995077 226.121092,27.5378155 L249.822333,40.9999824 C249.937469,41.0655089 249.986104,41.2066429 249.939454,41.3316473 L250,41.3669308 L249.933499,41.3457607 C249.888834,41.4405221 249.796526,41.5 249.694293,41.5 L249.694293,41.5 Z"
          id="Fill-97"
          fill="#66C6EB"
        ></path>
        <path
          d="M283.499989,111.235159 L266.236359,125.518203 L296.95888,113.441491 L283.499989,111.235159 Z M265.613766,126 C265.574236,126 265.536683,125.980132 265.51593,125.94437 C265.487271,125.895693 265.498141,125.83311 265.541624,125.798341 L283.396223,111.026546 C283.419941,111.005684 283.452553,110.99575 283.486153,111.001711 L297.404578,113.283541 C297.455966,113.291488 297.49352,113.332217 297.499449,113.383874 C297.50439,113.434537 297.475731,113.483213 297.428295,113.502088 L265.655272,125.992053 C265.641437,125.99702 265.627601,126 265.613766,126 L265.613766,126 Z"
          id="Fill-99"
          fill="#64C5EC"
        ></path>
        <path
          d="M283.587072,111.030826 L266.73465,124.410964 L296.726489,113.097851 L283.587072,111.030826 Z M265.267422,125.5 C265.172502,125.5 265.085659,125.452269 265.037189,125.373368 C264.969533,125.263295 264.996798,125.121078 265.097777,125.042176 L283.340683,110.558391 C283.399251,110.511635 283.479025,110.492153 283.555769,110.502868 L297.776672,112.739385 C297.895828,112.758867 297.985699,112.850432 297.998827,112.965375 C298.009934,113.08324 297.941269,113.192338 297.831201,113.234224 L265.365372,125.481492 C265.331039,125.494155 265.299735,125.5 265.267422,125.5 L265.267422,125.5 Z"
          id="Fill-101"
          fill="#66C6EB"
        ></path>
        <path
          d="M236.52626,214.915179 L252.981961,219.269543 L295.766584,197.065978 L236.52626,214.915179 Z M252.995994,219.5 C252.98597,219.5 252.973942,219.499028 252.963919,219.49611 L236.085223,215.029921 C236.035105,215.01728 236.001025,214.974494 236.000022,214.92393 C235.99902,214.874338 236.032098,214.830581 236.081213,214.815995 L296.851134,196.504909 C296.905262,196.488378 296.96841,196.514633 296.990462,196.568114 C297.014519,196.621596 296.991464,196.682856 296.939342,196.710083 L253.049119,219.487359 C253.033081,219.49611 253.014036,219.5 252.995994,219.5 L252.995994,219.5 Z"
          id="Fill-103"
          fill="#64C5EC"
        ></path>
        <path
          d="M295.604905,197.12829 L295.665748,197.267514 L296.393865,196.892091 L295.604905,197.12829 Z M237.208913,214.819224 L253.030001,218.973833 L294.168619,197.78888 L237.208913,214.819224 Z M253.062916,219.5 L252.97614,219.484637 L236.195534,215.079427 C236.083823,215.052543 236.003032,214.952686 236.000039,214.839387 C235.998045,214.727049 236.071854,214.628152 236.18556,214.592626 L296.657184,196.510908 C296.794828,196.472502 296.92948,196.538753 296.979351,196.658773 C297.032214,196.773992 296.980348,196.916095 296.860658,196.978506 L253.186596,219.469275 C253.148694,219.489438 253.105805,219.5 253.062916,219.5 L253.062916,219.5 Z"
          id="Fill-105"
          fill="#66C6EB"
        ></path>
      </g>
    </g>
  </svg>
)

export default LineArt4
