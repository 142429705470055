import React from "react"
import styled from "styled-components"

import colors from "../../styles/colors"
import ShowMoreIcon from "../Icons/ShowMore"

const ShowMoreButton = styled.div`
  height: 60px;
  width: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  user-select: none;

  background-color: ${colors.white};
  border-radius: 4px;

  :hover {
    background-color: /*${colors.lightGrey}*/#fdfdfd;
  }

  > svg {
    width: 24px;
    fill: ${colors.mediumGrey};
  }
`

export const ShowMore = ({ onClick }) => {
  return (
    <ShowMoreButton onClick={onClick} className="showMore">
      <ShowMoreIcon />
    </ShowMoreButton>
  )
}

export default ShowMore
