import _ from "lodash"
const tryAddTag = (tags, map, id_list, type) => {
  const id = _.get(id_list, "0")
  const name = _.get(map, [id, "name"])
  const tag_id = _.get(map, [id, type])

  if (!_.some([id, name, tags], _.isNil) && _.indexOf(tags, name) === -1) {
    tags.push({ name, type, id: tag_id })
  }
}
export const makeTags = (
  { sportID, productID, clientID },
  sportMap,
  productMap,
  clientMap
) => {
  const tags = []
  tryAddTag(tags, sportMap, sportID, "sport")
  tryAddTag(tags, clientMap, clientID, "client")
  tryAddTag(tags, productMap, productID, "product")

  return tags
}
