import React from "react"
import _ from "lodash"

import Quote from "./Quote"
import ShowMore from "./ShowMore"
import Placeholder from "./Placeholder"
import LineArt1 from "./LineArt/1"
import LineArt2 from "./LineArt/2"
import LineArt3 from "./LineArt/3"
import LineArt4 from "./LineArt/4"

export const getIcon = name => {
  switch (_.toLower(name)) {
    case "quote":
    case "quotes":
      return <Quote />
    case "more":
    case "showmore":
      return <ShowMore />
    case "lineart":
    case "lineart1":
      return <LineArt1 />
    case "lineart2":
      return <LineArt2 />
    case "lineart3":
      return <LineArt3 />
    case "lineart4":
      return <LineArt4 />
    case "placeholder":
    default:
      return <Placeholder />
  }
}
