import React from "react"

export const ShowMore = () => (
  <svg
    width="24px"
    height="6px"
    viewBox="0 0 24 6"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>More</title>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="UI/Button/More"
        transform="translate(-108.000000, -22.000000)"
        fill="#D5D5D5"
      >
        <path
          d="M120,28 C118.343146,28 117,26.6568542 117,25 C117,23.3431458 118.343146,22 120,22 C121.656854,22 123,23.3431458 123,25 C123,26.6568542 121.656854,28 120,28 Z M129,28 C127.343146,28 126,26.6568542 126,25 C126,23.3431458 127.343146,22 129,22 C130.656854,22 132,23.3431458 132,25 C132,26.6568542 130.656854,28 129,28 Z M111,28 C109.343146,28 108,26.6568542 108,25 C108,23.3431458 109.343146,22 111,22 C112.656854,22 114,23.3431458 114,25 C114,26.6568542 112.656854,28 111,28 Z"
          id="More-Icon"
        ></path>
      </g>
    </g>
  </svg>
)

export default ShowMore
