import _ from "lodash"
import React from "react"
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps"
import { compose, withProps } from "recompose"
import styled from "styled-components"

import colors from "../../styles/colors"
import { mapStyles } from "../../styles/map"
import { makeTags } from "../../utils/caseStudy"
import Tags from "../Tags"
import Card from "."

export { EmployeeCard } from "./employee"

export const CardDesc = styled.p.attrs(() => ({
  className: "description",
}))`
  margin: 0;
`

export const ProductDesc = styled.p.attrs(() => ({
  className: "description",
}))`
  margin: 0;
  font-weight: 300;
  font-size: 15px;
  white-space: pre-wrap;
  max-width: 100%;
`

export const CardImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const ProductCard = ({ title, desc, icon, linkAddress }) => {
  const content = <ProductDesc className="skipUnderline">{desc}</ProductDesc>
  const image = <CardImg src={icon} />

  const card_props = {
    cardType: "product",
    title,
    color: null,
    content,
    image,
    linkAddress,
  }
  return <Card {...card_props} />
}

export const WorkCard = ({
  title,
  tags,
  image: image_url,
  linkAddress = "#",
}) => {
  const content = <Tags tags={tags} />
  const image = <CardImg src={image_url} />
  const card_props = {
    cardType: "work",
    title,
    color: colors.blue,
    content,
    image,
    linkAddress,
  }
  return <Card {...card_props} />
}

export const CaseStudyCard = ({
  caseStudy: { name, case: caseId, cardImage: image_obj, ...rest },
  sportMap,
  productMap,
  clientMap,
}) => {
  const tags = makeTags(rest, sportMap, productMap, clientMap)
  const content = <Tags tags={tags} />

  const image_url = _.get(image_obj, "0.url")
  const image = <CardImg src={image_url} />

  const card_props = {
    cardType: "case",
    title: name,
    color: colors.blue,
    content,
    image,
    linkAddress: `/work/${caseId}`,
  }
  return <Card {...card_props} />
}

const Address = styled.div`
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 24px;
  max-width: 100%;
`

export const MapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.34&key=AIzaSyC3XD5b7pJNWf-vRRVPRCPiuMGsadBsQPc&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `240px`, width: "320px" }} />,
    containerElement: <div style={{ height: `240px`, width: "320px" }} />,
    mapElement: <div style={{ height: `240px`, width: "320px" }} />,
  }),
  withScriptjs,
  withGoogleMap
)(({ latitude: lat, longitude: lng, zoom = 17 }) => {
  const marker = {
    path: "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0",
    fillColor: "#47BCEC",
    fillOpacity: 1,
    strokeWeight: 3,
    strokeColor: "#fff",
    scale: 0.9,
  }
  return (
    <GoogleMap
      defaultZoom={zoom}
      defaultCenter={{ lat, lng }}
      defaultOptions={{
        disableDefaultUI: true,
        styles: mapStyles,
      }}
    >
      <Marker position={{ lat, lng }} icon={marker} />
    </GoogleMap>
  )
})

export const OfficeCard = ({
  city,
  address,
  email,
  // image: image_obj,
  linkAddress = "mailto:" + email,
  latitude,
  longitude,
  mapZoom,
}) => {
  // const image_url = _.get(image_obj, "0.url")
  const image = (
    <MapComponent latitude={latitude} longitude={longitude} zoom={mapZoom} />
  ) //<CardImg src={image_url} />
  const content = <Address className="skipUnderline">{address}</Address>
  const card_props = {
    cardType: "office",
    title: city,
    color: colors.blue,
    content,
    image,
    linkAddress,
    linkText: email,
    useLink: false,
    linkBottom: true,
    underlineTitle: false,
  }
  return <Card {...card_props} />
}
