import _ from "lodash"
import React from "react"
import styled, { css } from "styled-components"

import colors from "../../styles/colors"
import below from "../../styles/media"
import { isIE } from "../../utils/ie"
import { getIcon } from "../Icons"
import TitleDesc from "./description"

export const SectionTitleHeader = styled.h2`
  z-index: 1;
  color: ${colors.blue};
  text-align: center;
  font-weight: 600;
  font-size: 60px;

  box-sizing: border-box;
  position: relative;

  margin: 0;
  padding-bottom: 37px;

  &.isIE {
    height: 100px;
  }

  ${below.phone`
    font-size: 30px;
    padding-bottom: 26px;
  `}

  :after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);

    width: 80px;
    height: 5px;

    background-color: ${colors.blue};
    border-radius: 3px;
  }
`

export const SectionTitleWrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &.isIE {
    height: 100px;
  }

  ${below.tablet`
    margin: 0 20px;
  `}

  > svg {
    width: 128px;
    margin-bottom: 10px;
    z-index: 1;
    ${below.phone`
      width: 100px;
    `}
  }

  ${props =>
    props.bg_icon &&
    css`
      justify-content: center;
      min-height: 280px;

      &.isIE {
        height: 400px;
      }

      ${below.phone`
        min-height: 150px;
      `}
    `}
`

export const BgIcon = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);

  > svg {
    height: 320px;

    ${below.phone`
      height: 140px;
      width: 140px;
    `}
  }
`

export const SectionTitle = ({ title, desc, icon, bg_icon }) => {
  const ie = isIE()
  // console.log(ie)
  // console.log("render")
  return (
    <SectionTitleWrapper
      bg_icon={!_.isEmpty(bg_icon)}
      className={ie ? "isIE" : undefined}
    >
      {!_.isEmpty(bg_icon) && <BgIcon>{getIcon(bg_icon)}</BgIcon>}
      {!_.isEmpty(icon) && getIcon(icon)}
      <SectionTitleHeader className={ie ? "isIE" : undefined}>
        {title}
      </SectionTitleHeader>
      {!_.isEmpty(desc) && (
        <TitleDesc bg_icon={!_.isEmpty(bg_icon)}>{desc}</TitleDesc>
      )}
    </SectionTitleWrapper>
  )
}

export default SectionTitle
