import _ from "lodash"
import React from "react"
import styled from "styled-components"

import Tag from "./tag"

export const TagsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-right: -10px;
  margin-bottom: -5px;

  > div {
    margin-right: 10px;
    margin-bottom: 8px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`

export const Tags = ({ tags }) => {
  return (
    <TagsContainer className="skipUnderline">
      {_.map(tags, ({ name, type, id = "" }, i) => (
        /*
         * The way this link is done isn't great
         * Maybe remove the duct-type at some point :/
         */
        <Tag title={name} key={i} link={`${type}s/${id}`} />
      ))}
    </TagsContainer>
  )
}

export default Tags
