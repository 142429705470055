import styled, { css } from "styled-components"

import colors from "../../styles/colors"
import below from "../../styles/media"

export const TitleDesc = styled.p`
  text-align: center;
  font-size: 30px;
  line-height: 1.2;
  letter-spacing: 0.012em;
  color: ${colors.offBlack};
  z-index: 1;
  max-width: 25em;

  margin: 40px 0 60px;

  ${props =>
    props.bg_icon &&
    css`
      display: inline-block;
      width: auto;

      margin: 0;

      position: absolute;
      top: 270px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    `}

  ${below.phone`
    display: none;
  `}
`

export default TitleDesc
